import React, { useState } from "react";
import { Styles } from "../styles/courseTag";
import { Button } from "@mui/material";
import { colors } from "../../../common/element/elements";

interface CourseTagProps {
  courses: any[];
  onConcentrationChange?: (concentration: string | null) => void;
}

const CourseTag: React.FC<CourseTagProps> = ({
  courses,
  onConcentrationChange,
}) => {
  const [selectedConcentration, setSelectedConcentration] = useState<
    string | null
  >(null);

  const concentrations = Array.from(
    new Set(courses.map((course) => course.Concentration).filter(Boolean))
  );

  const handleConcentrationClick = (concentration: string | null) => {
    setSelectedConcentration(concentration);
    if (onConcentrationChange) {
      onConcentrationChange(concentration);
    }
  };

  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-tag">
        <h5>Course Tag</h5>
        <div className="tag-box">
          {concentrations.length > 1 && (
            <Button
              className="tag-button"
              sx={{
                border: `1px solid ${
                  selectedConcentration === null
                    ? colors.Button_border1
                    : colors.Button_border4
                }`,
                margin: "3px",
                color: colors.B_text2,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: colors.button_bg1,
                  color: colors.B_text3,
                },
              }}
              onClick={() => handleConcentrationClick(null)}
            >
              All
            </Button>
          )}
          {concentrations.map((concentration, index) => (
            <Button
              key={index}
              className="tag-button"
              sx={{
                border: `1px solid ${
                  selectedConcentration === concentration
                    ? colors.Button_border1
                    : colors.Button_border4
                }`,
                margin: "3px",
                color: colors.B_text2,
                "&:hover": {
                  backgroundColor: colors.button_bg1,
                  color: colors.B_text3,
                },
              }}
              onClick={() => handleConcentrationClick(concentration)}
            >
              {concentration}
            </Button>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default CourseTag;
