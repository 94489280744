import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import { Styles } from "./ContactUsStyle";
import Toast from "../../common/Toast";
import { api } from "../../../API/API";
import appStore from "../../../mobstore/MobStore";

const ContactUs: React.FC = () => {
  const history = useHistory();
  const [open, SetOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );

  const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const name = (
      document.getElementById("name2") as HTMLInputElement
    ).value.trim();
    const email = (
      document.getElementById("email2") as HTMLInputElement
    ).value.trim();
    const userphone = (
      document.getElementById("userphone2") as HTMLInputElement
    ).value.trim();
    const usermsg = (
      document.getElementById("user_comments") as HTMLTextAreaElement
    ).value.trim();

    let isValid = true;

    if (name === "") {
      setError(document.getElementById("name2"), "Name can't be blank");
      isValid = false;
    } else {
      setSuccess(document.getElementById("name2"));
    }

    if (email === "") {
      setError(document.getElementById("email2"), "Email can't be blank");
      isValid = false;
    } else if (!isEmail(email)) {
      setError(document.getElementById("email2"), "Not a valid email");
      isValid = false;
    } else {
      setSuccess(document.getElementById("email2"));
    }

    if (userphone === "") {
      setError(
        document.getElementById("userphone2"),
        "Phone number can't be blank"
      );
      isValid = false;
    } else if (!/^\d{10}$/.test(userphone)) {
      setError(
        document.getElementById("userphone2"),
        "Phone number must be 10 digits"
      );
      isValid = false;
    } else {
      setSuccess(document.getElementById("userphone2"));
    }

    if (usermsg === "") {
      setError(
        document.getElementById("user_comments"),
        "Message can't be blank"
      );
      isValid = false;
    } else {
      setSuccess(document.getElementById("user_comments"));
    }

    if (isValid) {
      const token = appStore.loginResponse.accessToken;
      try {
        const response = await api.post("action-contact", {
          body: {
            name: name,
            email: email,
            phone: userphone,
            message: usermsg,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response) {
          setToastMessage(response.message);
          setToastSeverity("success");
          SetOpen(true);
        } else {
          setToastMessage("Submission failed. Please try again.");
          setToastSeverity("error");
          SetOpen(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const setError = (input: HTMLElement | null, message: string) => {
    const formControl = input?.parentElement;
    const errorMsg = formControl?.querySelector(".input-msg2");
    formControl?.classList.add("error");
    if (errorMsg) errorMsg.textContent = message;
  };

  const setSuccess = (input: HTMLElement | null) => {
    const formControl = input?.parentElement;
    formControl?.classList.remove("error");
    formControl?.classList.add("success");
  };

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };

  return (
    <Styles>
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Contact Us" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Contact Us</h3>
                  </div>
                  <form
                    id="contact_form"
                    className="form"
                    onSubmit={formSubmit}
                  >
                    <p className="form-control">
                      <label htmlFor="user_name">User Name</label>
                      <input
                        type="text"
                        placeholder="Enter your Name"
                        id="name2"
                      />
                      <span className="input-msg2"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="login_user">User Email</label>
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        id="email2"
                      />
                      <span className="input-msg2"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="user_phone">Phone Number</label>
                      <input
                        type="tel"
                        placeholder="Phone number"
                        id="userphone2"
                      />
                      <span className="input-msg2"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="user_comments">Message</label>
                      <textarea
                        placeholder="Enter your message here"
                        id="user_comments"
                        rows={4}
                        style={{ width: "100%" }}
                      ></textarea>
                    </p>
                    <button type="submit">Contact Us</button>
                    <button
                      type="button"
                      onClick={() => history.push("/login")}
                    >
                      Go Back
                    </button>
                    <div className="not_account-btn text-center">
                      <p>
                        Haven't Any Account Yet?{" "}
                        <Link to={process.env.PUBLIC_URL + "/registration"}>
                          Click Here
                        </Link>
                      </p>
                    </div>
                    <div className="social-login text-center">
                      <p>Login With Social</p>
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <a
                            target="_blank"
                            href={
                              process.env.PUBLIC_URL +
                              "https://wa.me/90523 78789"
                            }
                          >
                            <i className="fab fa-whatsapp"></i> Whats-Aapp
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            target="_blank"
                            href={
                              process.env.PUBLIC_URL +
                              "https://www.facebook.com/nxtstepoverseaseducation"
                            }
                          >
                            <i className="fab fa-facebook-f"></i> Facebook
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            target="_blank"
                            href={
                              process.env.PUBLIC_URL +
                              "https://www.instagram.com/nxtstepoverseaseducation/"
                            }
                          >
                            <i className="fab fa-instagram"></i> Instagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Toast
        open={open}
        onClose={() => SetOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
};

export default ContactUs;
