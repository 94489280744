import React, { Component } from "react";
import { Styles } from "../components/styles/aboutUs";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import AboutUs from "../components/About";
import TabBox from "../components/TabBox";
import TestimonialSlider from "../components/TestimonialSlider";

class About extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Breadcroumb */}
          <BreadcrumbBox title="About Us" />
          <TabBox />
          <AboutUs />

          <TestimonialSlider />
        </div>
      </Styles>
    );
  }
}

export default About;
