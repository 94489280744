import React from "react";
import { FieldProps } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Themecolors } from "./Colors";

type Option = {
  id: string;
  name: string;
};

type RadioButtonProps = FieldProps & {
  label: string;
  options: Option[];
};

const RadioButton: React.FC<RadioButtonProps> = ({
  field,
  form: { touched, errors },
  label,
  options,
}) => (
  <FormControl
    component="fieldset"
    error={touched[field.name] && Boolean(errors[field.name])}
  >
    <legend>{label}</legend>
    <FormControl component="fieldset" variant="filled">
      <RadioGroup
        {...field}
        value={field.value || ""}
        style={{ flexDirection: "row", flexWrap: "wrap" }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.name}
            control={
              <Radio
                sx={{ "&.Mui-checked": { color: Themecolors.RB_Selected2 } }}
              />
            }
            label={option.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {touched[field.name] && errors[field.name] && (
      <div style={{ color: "red" }}>{errors[field.name] as any}</div>
    )}
  </FormControl>
);

export default RadioButton;
