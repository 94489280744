import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../components/styles/loginPage/account";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import { api } from "../API/API";
import Toast from "../components/common/Toast";
import appStore from "../mobstore/MobStore";
import LoginParent from "./LoginParent";

function Register() {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, SetSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("success");
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const form = document.getElementById("form_registration");
    form?.addEventListener("submit", formSubmit);

    return () => {
      form?.removeEventListener("submit", formSubmit);
    };
  }, []);

  async function formSubmit(e: any) {
    e.preventDefault();

    const fname = document.getElementById(
      "registration_fname"
    ) as HTMLInputElement;
    const lname = document.getElementById(
      "registration_lname"
    ) as HTMLInputElement;
    const email = document.getElementById(
      "registration_email"
    ) as HTMLInputElement;
    const user = document.getElementById(
      "registration_user"
    ) as HTMLInputElement;
    const password = document.getElementById(
      "registration_password"
    ) as HTMLInputElement;
    const cpassword = document.getElementById(
      "registration_cpassword"
    ) as HTMLInputElement;

    const fnameValue = fname.value.trim();
    const lnameValue = lname.value.trim();
    const emailValue = email.value.trim();
    const userValue = user.value.trim();
    const passwordValue = password.value.trim();
    const cpasswordValue = cpassword.value.trim();

    let isValid = true;

    if (fnameValue === "") {
      setError(fname, "First name can't be blank");
      isValid = false;
    }
    if (lnameValue === "") {
      setError(lname, "Last name can't be blank");
      isValid = false;
    }
    if (emailValue === "") {
      setError(email, "Email can't be blank");
      isValid = false;
    } else if (!isEmail(emailValue)) {
      setError(email, "Not a valid email");
      isValid = false;
    }
    if (userValue === "") {
      setError(user, "User name can't be blank");
      isValid = false;
    }
    if (passwordValue === "") {
      setError(password, "Password can't be blank");
      isValid = false;
    } else if (passwordValue.length < 6) {
      setError(password, "Password should be at least 6 characters long");
      isValid = false;
    }
    if (cpasswordValue === "" || passwordValue !== cpasswordValue) {
      setError(cpassword, "Password doesn't match");
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      try {
        const response = await api.post("create-user", {
          body: {
            userFirstName: fnameValue,
            userLastName: lnameValue,
            userEmail: emailValue,
            userName: userValue,
            userPassword: passwordValue,
          },
        });
        setLoading(false);

        if (response.message === "User registered successfully") {
          setToastMessage("User registered successfully");
          setToastOpen(true);
          SetSeverity("success");

          const user = {
            firstName: fnameValue,
            lastName: lnameValue,
            email: emailValue,
            userName: userValue,
            userPassword: passwordValue,
          };

          appStore.setUser(user);

          setTimeout(() => {
            history.push("/login");
          }, 1000);
        } else if (response.message === "ER_DUP_ENTRY") {
          setToastMessage("User already registered. Please login.");
          setToastOpen(true);
          SetSeverity("warning");
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        } else {
          setToastMessage("Registration failed");
          setToastOpen(true);
          SetSeverity("error");
        }
      } catch (error: any) {
        console.log(error, "error occurred");
      }
    }
  }

  function setError(input: any, message: any) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".registration_input-msg");
    formControl.className = "form-control text-left error";
    errorMsg.innerText = message;

    input.addEventListener("input", function handleInput() {
      formControl.classList.remove("error");
      errorMsg.innerText = "";
      input.removeEventListener("input", handleInput);
    });
  }
  function handleBlur(e: any) {
    const input = e.target;
    const inputValue = input.value.trim();

    if (inputValue === "") {
      input.classList.add("touched");
      setError(input, `${input.name} can't be blank`);
    } else if (input.id === "registration_email") {
      if (!isEmail(inputValue)) {
        setError(input, "Not a valid email");
      }
    } else if (input.id === "registration_password") {
      if (inputValue.length < 6) {
        setError(input, "Password should be at least 6 characters long");
      }
    } else if (input.id === "registration_cpassword") {
      const password = document.getElementById(
        "registration_password"
      ) as HTMLInputElement;
      const passwordValue = password.value.trim();

      if (inputValue !== passwordValue) {
        setError(input, "Passwords do not match");
      }
    }
  }

  function isEmail(email: any) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }

  // useEffect(() => {
  //   const userData = appStore.loginResponse.user[0];
  //   console.log("First User Data:", userData);
  // }, [appStore.loginResponse.user]);

  return (
    <Styles>
      {loading && <LoginParent />}
      {/* Main Wrapper */}
      <div className="main-wrapper registration-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Registration" />

        {/* Registration Area */}
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Registration</h3>
                  </div>
                  <form id="form_registration" className="form">
                    <p className="form-control">
                      <label htmlFor="registration_fname">First Name</label>
                      <input
                        type="text"
                        placeholder="First name"
                        id="registration_fname"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_lname">Last Name</label>
                      <input
                        type="text"
                        placeholder="Last name"
                        id="registration_lname"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_email">Email Address</label>
                      <input
                        type="email"
                        placeholder="Email address"
                        id="registration_email"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_user">User Name</label>
                      <input
                        type="text"
                        placeholder="Username"
                        id="registration_user"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_password">Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="registration_password"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_cpassword">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        placeholder="Confirm password"
                        id="registration_cpassword"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <button disabled={loading}>Register Now</button>
                  </form>
                  <div className="have_account-btn text-center">
                    <p>
                      Already have an account?{" "}
                      <Link to="/login">Login Here</Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>{" "}
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={severity}
      />
    </Styles>
  );
}

export default Register;
