import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import { CareerStyles } from "./CareerStyle";
import { api } from "../../../API/API";
import Toast from "../../common/Toast";
import appStore from "../../../mobstore/MobStore";

const Career: React.FC = () => {
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    const form = document.getElementById("form_registration");
    form?.addEventListener("submit", formSubmit);

    return () => {
      form?.removeEventListener("submit", formSubmit);
    };
  }, []);

  async function formSubmit(e: any) {
    e.preventDefault();
    const fname = document.getElementById(
      "registration_fname"
    ) as HTMLInputElement;
    const email = document.getElementById(
      "registration_email"
    ) as HTMLInputElement;
    const resume = document.getElementById(
      "registration_resume"
    ) as HTMLInputElement;
    const message = document.getElementById(
      "registration_message"
    ) as HTMLTextAreaElement;

    const fnameValue = fname.value.trim();
    const emailValue = email.value.trim();
    const messageValue = message.value.trim();
    const resumeFile = resume.files ? resume.files[0] : null;

    let isValid = true;

    if (fnameValue === "") {
      setError(fname, "First name can't be blank");
      isValid = false;
    }
    if (emailValue === "") {
      setError(email, "Email can't be blank");
      isValid = false;
    } else if (!isEmail(emailValue)) {
      setError(email, "Not a valid email");
      isValid = false;
    }
    if (!resumeFile) {
      setError(resume, "Resume can't be blank");
      isValid = false;
    }

    if (isValid) {
      const token = appStore.loginResponse.accessToken;
      let resumeBase64 = "";
      if (resumeFile) {
        resumeBase64 = await fileToBase64(resumeFile);
      }

      console.log("resune", resumeFile);
      try {
        const response = await api.post("action-resume", {
          body: {
            name: fnameValue,
            email: emailValue,
            message: messageValue,
            file: resumeBase64,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          setToastMessage(response.message);
          setToastSeverity("success");
          SetToastopen(true);
        } else {
          setToastMessage("Submission failed. Please try again.");
          setToastSeverity("error");
          SetToastopen(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  function setError(input: any, message: any) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".registration_input-msg");
    formControl.className = "form-control text-left error";
    errorMsg.innerText = message;

    input.addEventListener("input", function handleInput() {
      formControl.classList.remove("error");
      errorMsg.innerText = "";
      input.removeEventListener("input", handleInput);
    });
  }

  function isEmail(email: any) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }

  function handleBlur(e: any) {
    const input = e.target;
    const inputValue = input.value.trim();

    if (inputValue === "") {
      input.classList.add("touched");
      setError(input, `${input.name} can't be blank`);
    } else if (input.id === "registration_email") {
      if (!isEmail(inputValue)) {
        setError(input, "Not a valid email");
      }
    }
  }

  function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
      reader.readAsDataURL(file); // Convert file to base64
    });
  }

  return (
    <CareerStyles>
      <div className="main-wrapper registration-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Career" />

        {/* Registration Area */}
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3> Submit Your Profile for Evaluation</h3>
                  </div>
                  <form id="form_registration" className="form">
                    <p className="form-control">
                      <label htmlFor="registration_fname"> Name</label>
                      <input
                        type="text"
                        placeholder="Name"
                        id="registration_fname"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>

                    <p className="form-control">
                      <label htmlFor="registration_email">Email Address</label>
                      <input
                        type="text"
                        placeholder="Email address"
                        id="registration_email"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_resume">Upload Resume</label>
                      <input type="file" id="registration_resume" />
                      <span className="registration_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="registration_message">Message</label>
                      <textarea
                        rows={4}
                        placeholder="Enter your message"
                        id="registration_message"
                        style={{ width: "100%" }}
                      />
                      <span className="registration_input-msg"></span>
                    </p>
                    <button>Submit</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>{" "}
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </CareerStyles>
  );
};

export default Career;
