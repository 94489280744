import { action, makeAutoObservable } from "mobx";
import { api } from "../API/API";

interface User {
  userId?: number;
  userName?: string;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  userImage?: null | string;
  userAddress?: null | string;
  userServerEmail?: null | string;
  userPhoneOne?: null | string;
  userPhoneTwo?: null | string;
  userLastLogin?: null | string;
  userCreated?: null | string;
  userEnabled?: number;
  userLocked?: number;
  userType?: number;
  userPassword?: null | string;
  entities?: string;
  roles?: string;
}

interface LoginResponse {
  success: boolean;
  message: string;
  user: User[];
  accessToken: string;
  refreshToken: string;
}

interface DocumentGroup {
  dataTableId: number;
  documentGroupDescription: string;
  documentGroupId: number;
  documentGroupName: string;
}

interface Task {
  taskId: number;
  taskName: string;
  documentTypeAnswersId: number;
  documentTypeId: number;
  userId: number;
  // Add other fields as necessary
}

class AppStore {
  loginResponse: LoginResponse;
  selectedEntity: number;
  showEntityDialog: boolean;
  loading: boolean;
  documentGroups: DocumentGroup[];
  socket: any;
  tasks: Task[];
  selectedCountry: number[];

  constructor() {
    this.loginResponse = {
      success: false,
      message: "",
      user: [],
      accessToken: "",
      refreshToken: "",
    };

    this.selectedEntity = -1;
    this.showEntityDialog = false;
    this.loading = false;
    this.documentGroups = [];
    this.selectedCountry = [];
    this.tasks = [];

    makeAutoObservable(this, {
      setLoading: action,
      setSelectedCountries: action,
      setShowEntityDialog: action,
      setLoginResponse: action,
      setDocumentGroups: action,
      setUser: action,
      logout: action,
      setTasks: action,
    });

    const storedLoginData = localStorage.getItem("loginData");
    if (storedLoginData) {
      const parsedData = JSON.parse(storedLoginData);
      this.setLoginResponse(parsedData);
    }
  }

  async loadSocket() {}

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setSelectedCountries(ar: number[]) {
    this.selectedCountry = ar;
  }

  async getDocumentGroups() {
    try {
      const res = await api.get("get-document-groups");
      if (res.status) {
        this.setDocumentGroups(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch document groups:", error);
    }
  }

  setDocumentGroups(ar: DocumentGroup[]) {
    this.documentGroups = ar;
  }

  setShowEntityDialog(showEntityDialog: boolean) {
    this.showEntityDialog = showEntityDialog;
  }

  setLoginResponse(response: LoginResponse) {
    this.loginResponse = response;
    localStorage.setItem("loginData", JSON.stringify(response));
  }

  setUser(user: User) {
    this.loginResponse.user = [user];
  }

  setTasks(tasks: Task[]) {
    this.tasks = tasks;
  }

  logout() {
    this.loginResponse = {
      success: false,
      message: "",
      user: [],
      accessToken: "",
      refreshToken: "",
    };
    localStorage.removeItem("loginData");
  }
}

const appStore = new AppStore();

export default appStore;
