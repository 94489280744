import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import { Styles } from "./CounsellingStyle";
import { useHistory } from "react-router-dom";
import appStore from "../../../mobstore/MobStore";
import { api } from "../../../API/API";
import Toast from "../../common/Toast";

function OnlineCounselling() {
  const history = useHistory();
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    const form = document.getElementById("form_book_counselling");

    form?.addEventListener("submit", formSubmit);
  }, []);

  async function formSubmit(e: any) {
    e.preventDefault();
    const name = document.getElementById("user_name") as HTMLInputElement;
    const phnumber = document.getElementById("user_phone") as HTMLInputElement;
    const message = document.getElementById(
      "user_comments"
    ) as HTMLTextAreaElement;

    let fname = name.value.trim();
    let fnum = phnumber.value.trim();
    let fmessage = message.value.trim();

    let isValid = true;

    if (fname === "") {
      setError(name, "User name can't be blank");
      isValid = false;
    }

    if (fnum === "") {
      setError(phnumber, "Phone number can't be blank");
      isValid = false;
    }

    if (isValid) {
      const token = appStore.loginResponse.accessToken;

      try {
        const response = await api.post("online-counciling", {
          body: {
            name: fname,
            message: fmessage,
            Phone: fnum,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response) {
          setToastMessage("Your message is successfully submitted.");
          setToastSeverity("success");
          SetToastopen(true);
        } else {
          setToastMessage("Submission failed. Please try again.");
          setToastSeverity("error");
          SetToastopen(true);
        }
      } catch (error) {
        setToastMessage("Submission failed. Please try again.");
        setToastSeverity("error");
        SetToastopen(true);
      }
    }
  }

  function setError(input: any, message: any) {
    const formControl = input.parentElement;
    formControl.className = "form-control text-left error";

    input.addEventListener("input", function handleInput() {
      formControl.classList.remove("error");
      input.removeEventListener("input", handleInput);
    });
  }

  return (
    <Styles>
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Book Online Counselling" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Book Online Counselling</h3>
                  </div>
                  <form id="form_book_counselling" className="form">
                    <p className="form-control">
                      <label htmlFor="user_name">User Name</label>
                      <input
                        type="text"
                        placeholder="User name"
                        id="user_name"
                      />
                    </p>
                    <p className="form-control">
                      <label htmlFor="user_phone">Phone Number</label>
                      <input
                        type="tel"
                        placeholder="Phone number"
                        id="user_phone"
                      />
                      <span className="phone_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="user_comments">Message</label>
                      <textarea
                        placeholder="Enter your message here"
                        id="user_comments"
                        rows={4}
                        style={{ width: "100%" }}
                      ></textarea>
                    </p>
                    <button type="submit">Book Now</button>
                    <button type="button" onClick={() => history.push("/")}>
                      Go Back
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
}

export default OnlineCounselling;
