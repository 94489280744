import { Input } from "@mui/material";

export const Themecolors = {
  main_bg1: "rgb(22 55 90)", // blue
  main_bg2: "#ffab40", // orange
  orange_hv:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // orange hover
  blue_hv: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  white_hv: "linear-gradient(90deg,rgb(255 255 255) 0%, rgb(220 130 160)", // white hover

  // Main Header
  Menu_bg: "#ffab40", // Orange color for background
  Menu_Blue: "rgb(22 55 90)", // bllue for background
  Icons_blue: "rgb(22 55 90)", // blue
  Icons_Org: "#ffab40", //  orange
  Icons_bg_blue: "rgb(22 55 90)", // blue background color
  Icon_bg_Org: "#ffab40", // orange background color
  Manu_hv1:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  Manu_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  User_Text: "#F6F9FF", // white for useName

  // SideNavBar
  S_bg1: "#ffab40", // Orange color for background
  S_bg2: "rgb(22 55 90)", // bllue for background
  S_Text1: "#17375a", // text color
  S_text2: "black", // text color

  // Data-Grid

  Dg_bg1: "#ffab40", // Orange color for background
  Dg_bg2: "rgb(22 55 90)", // blue backgroundColor header
  Dg_bg3: "#fafafa", // white
  Dg_Icon1: "#ffab40", //  orange
  Dg_Icon2: "rgb(22 55 90)", // blue
  Dg_Icon_bg1: "#ffab40", // orange background color
  Dg_Icon_bg2: "rgb(22 55 90)", // blue background color
  Dg_Icon_bg3: "#fafafa",
  Dg_text1: "#fafafa", // white header text color
  Dg_text2: "#ffab40", // orange header active text color orange

  // HeaderBox

  H_bg1: "#ffab40", // Orange color for headerBox
  H_bg2: "rgb(22 55 90)", // blue backgroundColor headerBox
  H_hv: "linear-gradient(360deg,  white 0%, #eeeeee 100%)", // white hover headerbox
  H_hv2: "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  H_hv3: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  H_hv1: "", // blue border line
  H_text1: "rgb(22 55 90)", // blue
  H_text2: "#ffab40", // orange
  H_text3: "#F6F9FF", // white

  // TabBar

  T_bg1: "#ffab40", // Orange color for background
  T_bg2: "rgb(22 55 90)", // blue backgroundColor header
  T_Icon1: "#ffab40", //  orange
  T_Icon2: "rgb(22 55 90)", // blue
  T_Icon_bg1: "#ffab40", // orange background color
  T_Icon_bg2: "rgb(22 55 90)", // blue background color
  T_text1: "#F6F9FF", // white
  T_text2: "#ffab40", // orange

  // Buttons
  Button1: "#ffab40", //  orange
  Button2: "rgb(22 55 90)", // blue
  button_bg1: "#ffab40", // orange background color
  Button_bg2: "rgb(22 55 90)", // blue background color
  Button_bg3: "rgb(240 240 240)", // ASH background color
  Button_bg4: " #f5f5f5 ", // ash color
  Button_border1: "#ffab40", //  orange,
  Button_border2: "rgb(22 55 90)", // blue
  B_hv1: "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  B_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  B_hv3: "linear-gradient(270deg, #f5f5f5 0%, rgb(220 220 220) 100%)", // ash hover

  // US & UK Document form
  F_bg1: "#ffab40", //  orange
  F_bg2: "rgb(22 55 90)", // blue
  F_text1: "#F6F9FF", // white

  // UserHeaderComponent
  UH_Icon1: "#ffab40", //  orange
  UH_Icon2: "rgb(22 55 90)", // blue
  UH_Icon_bg1: "#ffab40", // orange background color
  UH_Icon_bg2: "rgb(22 55 90)", // blue background color
  UH_text1: "#F6F9FF", // white
  UH_text2: "#ffab40", // orange
  UH_text3: "rgb(22 55 90)", // blue
  UH_hv1:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  UH_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover

  //Dashboard StatCards

  DB_bg: "#F6F9FF", //white back-groung white entire page
  DB_bg1: "#ffab40", //  orange
  DB_bg2: "rgb(22 55 90)", // blue
  DB_Icon1: "#ffab40", //  orange
  DB_Icon2: "rgb(22 55 90)", // blue
  DB_Icon_bg1: "#ffab40", // orange background color
  DB_Icon_bg2: "rgb(22 55 90)", // blue background color
  DB_text1: "#ffab40", // orange
  DB_text2: "rgb(22 55 90)", // blue
  DB_text3: "#F6F9FF", // white
  DB_text4: "green", // Green
  DB_text5: "red", // RED
  DB_hv1:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  DB_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  DB_hv3: "linear-gradient(90deg,rgb(255 255 255) 0%, rgb(220 130 160)", // white hover

  //Text field & numeric Fields & TextArea

  InputText_Color1: "rgb(22 55 90)", // blue
  InputText_Color2: "black", // black
  InputText_Color3: "#ffab40", // orange
  Inpute_Border1: "rgb(22 55 90)", // blue
  Inpute_Border2: "#ffab40", // orange
  Inpute_hv1: "#ffab40", // hover orange
  Inpute_hv2: "rgb(22 55 90)", // hover  blue
  // radio button
  RB_Selected1: "rgb(22 55 90)", // black
  RB_Selected2: "#ffab40", // orange
  // Upload Button
  UP_Button1: "rgb(22 55 90)", // blue
  UP_Button2: "#ffab40", // orange
  UP_Button_Border: "#ffab40", //orange hover

  blue: "#2c97ea",
  red: "#f44336",
  Green: "#76ff03",
  purple: "#84479c",
  black1: "#182B49", // text color
  text1: "#fafafa",
  text2: "#666666",
  border1: "#eeeeee",
  border2: "#3e3e3e",
  footer1: "#1a1b25",
  footer2: "#16171f",
  ftext: "#8D8E92",
  // HeaderBox color
};

export const fonts = {
  roboto: "'Roboto', sans-serif",
  poppins: "'Poppins', sans-serif",
};
