import styled from "styled-components";
import { ServiceStyle } from "../Services/ServiceStyle";

export const UsaStyle = styled(ServiceStyle)`
  .main-wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding: 0; /* Remove padding in mobile view */
    }
  }

  .main-wrapper .blog-details-area {
    padding: 0;
    margin-top: 6em;
  }

  .header {
    border-bottom: 1px solid #bdbdbd;
  }

  .flag-image {
    border-radius: 17px;
    margin-top: 3em;
    width: 100%;
  }

  .country-flag {
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    border: 1px solid #bdbdbd;
    padding: 1px;
    box-sizing: border-box;
    margin-right: 1em;
  }

  .parent {
    display: flex;
    width: calc(100% - 100px);
    margin: 0 100px;

    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
    }
  }

  .flex-container {
    display: flex;
    gap: 60px;
    flex: 1;
  }

  .main-content {
    flex: 0 0 65%;
  }

  .destinations-container {
    flex: 0 0 32%;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between elements */
  }

  .application-box {
    flex: 1 1 calc(50% - 30px);
    padding: 0 20px 20px;
    border-radius: 8px;
    background-color: white;
  }

  .info-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2em;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #bdbdbd;

    .info-item {
      flex: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.separator {
        border-left: 1px dotted black;
        height: auto;
        margin: 0 10px;
        padding-left: 10px;
      }
    }
  }

  .overview-button {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 3em;

    .styled-button {
      transition: background-color 0.3s, color 0.3s;
      &:hover {
        background-color: #cfd8dc;
        color: black;
      }
    }

    .active-button {
      background-color: #ffab40;
      color: white;
    }
  }

  .styled-button,
  .enroll-button {
    height: 3.25em;
    border: 1px solid #bdbdbd;
    cursor: pointer;
    font-weight: bold;
    padding: 0 2em;
    transition: background-color 0.3s;
    margin-bottom: 0.4em;

    &:hover {
      background-color: #cfd8dc;
    }
  }

  .enroll-button {
    background-color: #002147;
    color: #ffffff;

    &:hover {
      background-color: #f5f5f5;
      color: black;
    }
  }

  .Lifestyletips {
  }

  .Lifestyletips h4 {
    margin-top: 1em;
  }

  .destinations,
  .related-links {
    margin-top: 1em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .destinations-title,
  .related-links-title,
  .form-title {
    text-decoration: underline;
    margin-bottom: 0.5em;
  }

  .destination-image {
    width: 5em;
    height: 2.5em;
  }

  .destination-item {
    display: flex;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid #ccc;
  }

  .destination-location {
    margin-left: 2em;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 1.3em;
    font-weight: bold;
    color: black;
  }

  .related-links-container {
    display: flex;
    flex-wrap: wrap;
  }

  .related-link-item {
    margin: 0.2em;
  }

  .related-link-button {
    background-color: transparent;
    color: inherit;
    border: 1px solid #b0b0b0;
    margin: 0 0.5em;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .form-container {
    max-width: 600px;
    padding: 1.6em;
    border: 1px solid #ccc;
    border-radius: 5px;

    input,
    select,
    textarea {
      width: 100%;
      padding: 10px;
      margin-top: 1.2em;
    }

    button {
      padding: 10px 20px;
      background-color: rgb(255, 171, 64);
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 1.5em;

      &:hover {
        background-color: rgb(255, 150, 0);
        transition: background-color 0.3s;
      }
    }
  }

  .button-content {
    margin-top: 1.5em;
    .text {
      font-size: 1.05em;
    }

    h4 {
      font-size: 1.5em;
      color: black;
      margin-bottom: 10px;
      margin-top: 1.5em;
    }
  }

  .point-text {
    margin-top: 1.5em;
    font-size: 1.1em;
  }

  .text {
    margin-top: 1.5em;
  }

  .point-wise {
    margin-top: 1.5em;

    .point-item {
      margin-top: 1em;
      color: #424242;
      font-size: 1.08em;

      .fas.fa-check-double {
        margin-right: 1em;
        color: rgb(255, 171, 64);
      }
    }
  }

  .application {
    margin-top: 1em;
  }

  .application h2,
  .university h2 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #212121;
  }

  .application h4,
  .university h4 {
    font-size: 1.35em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #333;
  }

  .application p,
  .university p {
    margin-top: 1.5em;
  }

  .collapse-content {
    padding: 1em;
    margin-top: 0.5em;
  }
  .Education {
    h4 {
      color: black;
    }
    p {
      margin-bottom: 1.5em;
    }
  }

  .collapse p {
    font-size: 1em;
    margin-right: 30em;
  }

  .university-row {
    background-color: #f5f5f5;
    font-size: 1.05em;
    margin-bottom: 1em;
  }

  .university-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .area-label {
    margin-right: 1.4em;
    padding: 0.5em;
  }
  .university-list {
    background-color: #f5f5f5;
    padding: 0.1em;
    box-sizing: border-box;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  .university-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .additional {
    margin-left: auto;
    padding: 0.5em;
  }

  .button-eligibility {
    h4 {
      margin-top: 1.2em;
      color: black;
    }
    .text {
      margin-top: 1em;
    }

    .eligibility-text,
    .h4 {
      color: black;
      font-size: 1.5em;
      margin-top: 1em;
    }
    .eligibilitypoint-text,
    .p {
      margin-top: 1em;
      font-size: 1.1em;
    }

    .eligibilitypoint-item {
      margin-top: 1em;
      color: #424242;
      font-size: 1.08em;

      .fas.fa-check-double {
        margin-right: 1em;
        color: rgb(255, 171, 64);
      }
    }
    .note {
      p {
        margin-top: 1em;
      }
    }
  }
  .work-study {
    h4 {
      font-size: 1.5rem;
      color: black;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
      color: #666;
      line-height: 1.6;
      margin-top: 1.5em;
    }
    .content {
      margin-top: 20px;
    }
  }

  .button-process {
    padding: 20px;
  }

  .text {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .process-Courses {
    margin-top: 20px;
  }

  .process-document,
  .process-Accommodation {
    margin-top: 15px;
  }

  .process-application h4 {
    font-size: 20px;
    color: black;
  }

  .process-application p {
    font-size: 1em;
    line-height: 1.5;
    color: #666;
    margin-top: 1em;
  }
  .process-Accommodation h4 {
    font-size: 20px;
    color: black;
  }

  .process-Accommodation p {
    font-size: 1em;
    line-height: 1.5;
    color: #666;
    margin-top: 1em;
  }

  .h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: black;
  }

  .process-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .process-item i {
    color: rgb(255, 171, 64);
    margin-right: 8px;
  }

  .button-degree {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    gap: 2em;
  }

  .degree-box {
    border: 1px solid #ccc;
    padding: 2em;
  }

  .button-degree h6 {
    font-size: 18px;
    margin: 8px 0;
    color: black;
  }

  .button-degree p {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
  }

  .button-otherinfo {
    margin-top: 1.5em;

    h4 {
      color: black;
    }

    p {
      margin-top: 1.5em;
    }
  }

  .main-dropdown {
    margin-top: 2em;
    border: 1px solid #ccc;

    .dropdown-header {
      cursor: pointer;
      height: 3.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      border-bottom: 1px solid #ccc;

      span {
        font-size: 1.3em;
        font-weight: bold;
        color: black;
      }

      a {
        text-decoration: none;
        color: black;
        font-size: 1.3em;
        font-weight: bold;
        margin-left: 0.5em;
      }
    }

    .collapse-content {
      padding: 10px;
      margin-left: 0.5em;
    }
  }

  .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .follow-label {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1em;
    text-decoration: underline;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 1em;
  }

  .social-media a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    transition: background-color 0.3s;
  }

  .social-media a.facebook {
    background-color: #3b5998;
    color: white;
  }

  .social-media a.facebook:hover {
    background-color: #2e4a77;
  }

  .social-media a.instagram {
    background-color: #c13584;
    color: white;
  }

  .social-media a.instagram:hover {
    background-color: #a72c6b;
  }

  .social-media a.whatsapp {
    background-color: #25d366;
    color: white;
  }

  .social-media a.whatsapp:hover {
    background-color: #1e8c50;
  }

  .social-media a.linkedin {
    background-color: rgb(10, 102, 194);
    color: white;
    border-radius: 50%;
    align-items: center;
    transition: background-color 0.3s;
  }

  .social-media a.linkedin:hover {
    background-color: rgb(9, 87, 158);
  }

  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .info-box {
      flex-direction: column; /* Stack items vertically on mobile */
      align-items: stretch; /* Allow items to stretch */
      text-align: center;
    }
    .flag-country {
      margin-bottom: 1em; /* Space below flag on mobile */
    }

    .info-item:not(:last-child) {
      border-top: 1px dotted #bdbdbd; /* Mobile style */
      padding-top: 1em; /* Add some padding on top */
      margin-top: 1em; /* Add some margin on top */
    }

    .separator {
      display: none; /* Remove vertical separator for mobile */
    }

    .enroll-button {
      width: 100%; /* Full width for mobile */
    }

    .flex-container {
      margin-left: 1em;
      margin-right: 1em;
      flex-direction: column;
      gap: 0;
    }

    .main-content {
      flex: 0;
    }

    .destinations-container {
      width: 350px; /* Increased width for sidebar */
      min-height: 100vh; /* Ensure it takes full height */
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .overview-button {
      flex-direction: column; /* Stack buttons on mobile */
    }

    .styled-button {
      width: 100%; /* Full width for buttons on mobile */
    }

    .application-box {
      flex: 1 1 100%; /* Full width on mobile */
    }

    .form-container {
      padding: 1em;
    }

    .related-links-container {
      flex-direction: column;
    }
  }

  @media (max-width: 1007px) {
    .parent {
      flex-direction: column; /* Stack on small screens */
    }

    .destinations-container {
      width: 100%; /* Full width on small screens */
    }
  }
`;
