import React from "react";
import { FieldProps } from "formik";
import { TextField } from "@mui/material";
import { Themecolors } from "./Colors";

type TextareaProps = FieldProps & {
  label: string;
  placeholder?: string;
  rows?: number;
};

const CustomTextarea: React.FC<TextareaProps> = ({
  field,
  form: { touched, errors },
  label,
  placeholder,
  rows = 6,
}) => (
  <TextField
    {...field}
    label={label}
    placeholder={placeholder}
    multiline
    rows={rows}
    variant="filled"
    fullWidth
    error={touched[field.name] && Boolean(errors[field.name])}
    InputLabelProps={{
      sx: {
        color: Themecolors.InputText_Color1,
        "&.Mui-focused": {
          color: Themecolors.InputText_Color1,
        },
      },
    }}
    sx={{
      "& .MuiFilledInput-root.Mui-focused:after": {
        borderBottom: `2px solid ${Themecolors.Inpute_Border2}`,
      },
    }}
    InputProps={{
      sx: {
        color: Themecolors.InputText_Color1,
        "& .MuiInputBase-input": {
          color: Themecolors.InputText_Color1,
        },
      },
    }}
  />
);

export default CustomTextarea;
