import React from "react";
import { FaSearch } from "react-icons/fa";
import { LoadingStyles } from "../styles/loading";

const LoadingComponent: React.FC = () => {
  return (
    <LoadingStyles>
      <div className="loading-container">
        <div className="loading-icon">
          <FaSearch />
        </div>
        <div className="loading-text">
          Our AI is evaluating your profile, Please wait ...
        </div>
      </div>
    </LoadingStyles>
  );
};

export default LoadingComponent;
