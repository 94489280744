import React from "react";
import { FieldProps } from "formik";
import { Typography } from "@mui/material";

type SingleSelectionButtonProps = FieldProps & {
  label: string;
  options: any[];
  onChange: (selectedOption: string) => void;
};

const FormFiledButton: React.FC<SingleSelectionButtonProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  options,
  onChange,
}) => {
  const handleClick = (value: string) => {
    if (field.value !== value) {
      setFieldValue(field.name, value);
      onChange(value);
    }
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <Typography variant="subtitle2" align="left" gutterBottom>
        {label}
      </Typography>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5em" }}>
        {options.map((option) => (
          <button
            type="button"
            key={option.id}
            onClick={() => handleClick(option.id)}
            style={{
              padding: "7px 14px",
              border: ` 0.125em solid ${
                field.value === option.id ? "#f8b400" : "#ccc"
              }`,
              backgroundColor: "#f9f9f9",
              transition: " background-color 0.3s, border-color 0.3s",
              color: field.value === option.id ? "#f8b400" : "inherit",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {option.name}
          </button>
        ))}
      </div>

      {touched[field.name] && errors[field.name] && (
        <div style={{ color: "red" }}>{errors[field.name] as any}</div>
      )}
    </div>
  );
};

export default FormFiledButton;
