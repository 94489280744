import React from "react";
import { Box, Link } from "@mui/material";
import { BreadcrumbBox } from "../Breadcrumb";
import { ErrorStyles } from "./ErrorStyles";
import TestimonialSlider from "../../TestimonialSlider";

function SomthingWentWrong() {
  const Reload = () => {
    window.location.reload();
  };

  return (
    <ErrorStyles>
      <BreadcrumbBox title="Not Found 404" />
      <Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "7em 7em 13em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "5em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1em",
              }}
            >
              <i
                className="fas fa-exclamation-circle"
                style={{
                  fontSize: "5em",
                  marginRight: "0.2em",
                }}
              ></i>
              <h1 style={{ fontSize: "3.4em" }}>Something went wrong</h1>
            </Box>
            <Box
              sx={{
                fontSize: "1.2em",
                fontWeight: 500,
                marginBottom: "1.5em",
              }}
            >
              We couldn't complete the request - please reload the page
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{}}>
                <div
                  style={{
                    marginTop: "1em",
                    textDecoration: "underline",
                    color: "blue",
                    fontWeight: "bold",
                    marginRight: "29em",
                  }}
                >
                  <a href="/">Reload</a>
                </div>
                <div
                  style={{
                    marginTop: "1em",
                    textDecoration: "underline",
                    color: "blue",
                    fontWeight: "bold",
                    marginRight: "27em",
                  }}
                >
                  <a href="/">Go to Home</a>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>{" "}
      <TestimonialSlider />
    </ErrorStyles>
  );
}

export default SomthingWentWrong;
