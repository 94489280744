import React, { Component } from "react";
import { Styles } from "../styles/courseSearch.js";
import { values } from "mobx";

interface CourseSearchProps {
  onSearchChange?: (query: string) => void;
}

class CourseSearch extends Component<CourseSearchProps> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // console.log("onChange", value);
    if (this.props.onSearchChange) {
      this.props.onSearchChange(value);
    }
  };

  render() {
    return (
      <Styles>
        {/* Course Search */}
        <div className="course-search">
          <form action="#">
            <input
              type="text"
              name="search"
              placeholder="Search Course Here"
              onChange={this.handleChange}
            />
            <button type="submit">
              <i className="las la-search"></i>
            </button>
          </form>
        </div>
      </Styles>
    );
  }
}

export default CourseSearch;
