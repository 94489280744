import React from "react";
import { FieldProps } from "formik";

const CustomDatePicker: React.FC<FieldProps> = ({ field, form, ...props }) => {
  return (
    <div className="custom-date-picker">
      <input
        className="custom-date-input"
        type="date"
        {...field}
        {...props}
        onChange={(e) => {
          const value = e.target.value;
          form.setFieldValue(field.name, value);
        }}
      />
    </div>
  );
};

export default CustomDatePicker;
