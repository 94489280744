import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import { Link } from "react-router-dom";
import Datas from "../data/instructor/instructor.json";

class TabBox extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="why">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="why">
                        <i className="las la-arrow-right"></i> Why GradWalk
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mission">
                        <i className="las la-arrow-right"></i> Our Mission
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="vision">
                        <i className="las la-arrow-right"></i> Our Vision
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Our Story
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="instructors">
                        <i className="las la-arrow-right"></i> Instructors
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="why">
                      <h4 className="tab-title">Why GradWalk</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            Pioneering Global Education Consultancy
                          </h6>
                          At Grad Walk, we stand as a pioneering consultancy
                          firm with over 14 years of dedicated service in
                          guiding students and parents...
                        </li>
                        <li>
                          <h6 className="title">
                            Strategic Partnerships with Top Institutions
                          </h6>
                          We have strategically partnered with some of the
                          world’s leading colleges and universities...
                        </li>
                        <li>
                          <h6 className="title">
                            Management Consulting for International Education
                          </h6>
                          Leadership in As recognized leaders in management
                          consulting for international universities...
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mission">
                      <h4 className="tab-title">Our Mission</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            Establishing a Cutting-Edge EdTech Ecosystem
                          </h6>
                          Our mission is focused on constructing a comprehensive
                          global EdTech ecosystem...
                        </li>
                        <li>
                          <h6 className="title">
                            Innovative Online Platform with a Human Touch
                          </h6>
                          We differentiate ourselves by utilizing an
                          ultra-efficient online platform...
                        </li>
                        <li>
                          <h6 className="title">
                            Expanding International Reach and Expertise
                          </h6>
                          Each day, we transcend international boundaries...
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="vision">
                      <h4 className="tab-title">Our Vision</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            Transformative Impact in Study Abroad Services
                          </h6>
                          Our vision centers on revolutionizing the Study Abroad
                          Service Sector...
                        </li>
                        <li>
                          <h6 className="title">
                            Global Connectivity Between Key Stakeholders
                          </h6>
                          We are committed to building a robust network that
                          connects educational institutions...
                        </li>
                        <li>
                          <h6 className="title">
                            Focus on Continual Innovation
                          </h6>
                          We place a strong emphasis on continual innovation...
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Our Story</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            Established Brand in Global Education
                          </h6>
                          Since its founding in 2012, GradWalk Overseas has
                          grown into a highly respected and valued brand...
                        </li>
                        <li>
                          <h6 className="title">
                            Strategic Growth and Expansion
                          </h6>
                          Our organization has experienced a steady growth
                          trajectory...
                        </li>
                        <li>
                          <h6 className="title">
                            Commitment to Integrity and Customer Focus
                          </h6>
                          At the heart of our success are our core values of
                          integrity and honest business practices...
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="instructors">
                      <section className="instructor-area">
                        <Container>
                          <Row>
                            {Datas.map((data, i) => (
                              <Col lg="3" md="4" sm="6" key={i}>
                                <div className="instructor-item">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/instructor-details"
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `/assets/images/${data.personImage}`
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </Link>
                                  <div
                                    className="img-content text-center"
                                    style={{
                                      background: "#ffffff",
                                      boxShadow:
                                        "0 10px 18px rgba(0, 0, 0, 0.07)",
                                      position: "relative",
                                      bottom: "60px",
                                      left: "10%",
                                      width: "80%",
                                      zIndex: 1,
                                      borderRadius: "5px",
                                      padding: "15px 0",
                                    }}
                                  >
                                    <h5>
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/instructor-details"
                                        }
                                      >
                                        {data.personName}
                                      </Link>
                                    </h5>
                                    <p>
                                      personTitle : <b>{data.personTitle}</b>
                                    </p>
                                    <p>Counsellor : </p>
                                    <b>Contact by</b>
                                    <div>
                                      <b>email:</b>
                                      <p>{data.email}</p>
                                    </div>
                                    <ul
                                      className="list-unstyled list-inline"
                                      style={{
                                        display: "flex",
                                        listStyle: "none",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {data.socialLinks &&
                                        data.socialLinks.facebook && (
                                          <li
                                            className="list-inline-item"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              href={
                                                process.env.PUBLIC_URL +
                                                data.socialLinks.facebook
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "1.7em",
                                                width: "1.7em",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  "rgb(66, 103, 178)" /* Facebook color */,
                                                textDecoration: "none",
                                                color: "white",
                                              }}
                                            >
                                              <i className="fab fa-facebook-f"></i>
                                            </a>
                                          </li>
                                        )}
                                      {data.socialLinks &&
                                        data.socialLinks.whatsapp && (
                                          <li
                                            className="list-inline-item"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              href={
                                                process.env.PUBLIC_URL +
                                                data.socialLinks.whatsapp
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "1.7em",
                                                width: "1.7em",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  "#4caf50" /* WhatsApp color */,
                                                textDecoration: "none",
                                                color: "white",
                                              }}
                                            >
                                              <i className="fab fa-whatsapp"></i>
                                            </a>
                                          </li>
                                        )}
                                      {data.socialLinks &&
                                        data.socialLinks.instagram && (
                                          <li
                                            className="list-inline-item"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              href={
                                                process.env.PUBLIC_URL +
                                                data.socialLinks.instagram
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "1.7em",
                                                width: "1.7em",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  "#ef5350" /* Instagram color */,
                                                textDecoration: "none",
                                                color: "white",
                                              }}
                                            >
                                              <i className="fab fa-instagram"></i>
                                            </a>
                                          </li>
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </section>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBox;
