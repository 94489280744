import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/header.js";
import LoginParent from "../pages/LoginParent";
import { api } from "../API/API";
import styled from "styled-components";
import { Box, Paper, Typography } from "@mui/material";
import MainDialog from "./common/MainDialog";
import DocumentForm from "./common/DocumentForm";
import appStore from "../mobstore/MobStore";
import Toast from "./common/Toast";

const StyledDiv = styled.div`
  .main-dilog {
    display: flex;
    flex-wrap: wrap;
  }

  .main-title {
    margin: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
    padding: 0.4em 0.6em 0.4em 0.6em;
    border-radius: 4px;

    &.active {
      color: black;
      border: 1px solid orange;
      padding: 0.4em 0.6em 0.4em 0.6em;
      border-radius: 4px;
    }
  }
`;

interface Document {
  documentTypeId: number;
  documentTypeName: string;
  documentTypeDescription: string;
  documentGroupId: number;
  documentTypeTableId: number;
}

const Header = observer(() => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [entityDocuments, setEntityDocuments] = useState<Document[]>([]);
  const [activeDocumentIds, setActiveDocumentIds] = useState<number[]>([]);
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );
  const history = useHistory();

  useEffect(() => {
    const storedLoginData = localStorage.getItem("loginData");
    if (storedLoginData) {
      const parsedData = JSON.parse(storedLoginData);
      appStore.setLoginResponse(parsedData);
    }
  }, []);

  const handleApply = async () => {
    if (!isLoggedIn) {
      history.push("/registration");
      return;
    }

    try {
      const token = appStore.loginResponse.accessToken;
      const documentTypeRoles = appStore.loginResponse.user[0].roles;

      const response = await api.post(`document-type/get-type-by-roles`, {
        body: {
          documentTypeRoles: "2,5,6,7",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEntityDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setActiveDocumentIds([]);
  };

  const handleOutsideClick = () => {
    setDialogOpen(false);
    setActiveDocumentIds([]);
  };

  const handleLogout = async () => {
    const token = appStore.loginResponse.accessToken;
    try {
      const response = await api.post("/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setToastMessage("Logout successful...!");
      setToastSeverity("success");
      SetToastopen(true);
    } catch (error) {
      console.log("error", error);
    }
    appStore.logout();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      SetToastopen(false);
    }, 1000);
  };

  const { loginResponse } = appStore;
  const isLoggedIn = loginResponse.success;
  const userName = isLoggedIn ? loginResponse.user[0]?.userName : "";

  return (
    <Styles>
      {/* Topbar */}
      {loading && <LoginParent />}
      <section className="top-bar">
        <Container>
          <Row>
            <Col lg="6" md="5">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="las la-map-marker"></i>108, Topaz Plaza,
                    Panjagutta, Hyderabad, Telangana, 500082
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6" md="7">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-social d-flex">
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.facebook.com/nxtstepoverseaseducation"
                      }
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li> */}
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.linkedin.com/company/nxts-step-overseas-education/"
                      }
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.instagram.com/nxtstepoverseaseducation/"
                      }
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={process.env.PUBLIC_URL + "https://wa.me/9052378789"}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled list-inline bar-lang">
                  <li className="list-inline-item">
                    <Dropdown>
                      <Dropdown.Toggle as="a">
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/us.png"}
                          alt=""
                        />
                        English <i className="las la-angle-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/us.png"
                            }
                            alt=""
                          />{" "}
                          English
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/fra.png"
                            }
                            alt=""
                          />{" "}
                          French
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/ger.png"
                            }
                            alt=""
                          />{" "}
                          German
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/spa.png"
                            }
                            alt=""
                          />{" "}
                          Spanish
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/bra.png"
                            }
                            alt=""
                          />{" "}
                          Brazilian
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
                <ul className="list-unstyled list-inline bar-login">
                  {isLoggedIn ? (
                    <li className="list-inline-item">
                      <Link
                        to={process.env.PUBLIC_URL + "/"}
                        onClick={handleLogout}
                      >
                        <i className="las la-sign-out-alt"></i> Log Out
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/login"}>
                          <i className="las la-user"></i> Log In
                        </Link>
                      </li>{" "}
                    </>
                  )}
                  {isLoggedIn ? (
                    <>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/"}>
                          <i className="las la-user-edit"></i> {userName}
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to={process.env.PUBLIC_URL + "/user-applications"}
                        >
                          <i className="	far fa-file-alt"></i> My Applications
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/registration"}>
                          <i className="las la-user-edit"></i> Register
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Logo Area */}
      <section className="logo-area">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/GradWalkLogo.png"
                    }
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="logo-contact-box d-flex justify-content-end">
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="box-content">
                    <p>Call Us Now</p>
                    <span>+919052378789</span>
                  </div>
                </div>
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-envelope"></i>
                  </div>
                  <div className="box-content">
                    <p>Enquery Us</p>
                    <span>help@gradwalk.in</span>
                  </div>
                </div>
                <div className="apply-btn">
                  <Link
                    to={process.env.PUBLIC_URL + "#"}
                    onClick={() => {
                      handleApply();
                    }}
                  >
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Navbar */}
      <section className="main-menu">
        <Container>
          <Row>
            <Col md="12">
              <div className="main-menu-box">
                <div className="menu-box d-flex justify-content-between">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Study In <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item active">
                          <Link
                            className="nav-link"
                            to={
                              process.env.PUBLIC_URL + "/study-in-united-states"
                            }
                          >
                            United States
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-canada"}
                          >
                            Canada
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-uk"}
                          >
                            United Kingdom
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-ireland"}
                          >
                            Ireland
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-australia"}
                          >
                            Australia
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={
                              process.env.PUBLIC_URL + "/study-in-switzerland"
                            }
                          >
                            Switzerland
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-uae"}
                          >
                            United Arab Emirates
                          </Link>
                        </li>{" "}
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/study-in-singapore"}
                          >
                            Singapore
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Services <i className="las la-angle-down"></i>
                      </Link>

                      <ul className="dropdown list-unstyled">
                        <div
                          className="box-content"
                          style={{
                            background:
                              "linear-gradient(90deg, rgb(255 172 65) 0%,rgb(255 228 126) 100%)",
                            padding: "10px",
                            color: "white",
                          }}
                        >
                          <span>
                            <i
                              className="fas fa-graduation-cap"
                              style={{ marginRight: "5px" }}
                            ></i>
                            <strong>For Students</strong>
                          </span>
                        </div>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Councelling"}
                          >
                            Councelling
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Preparation"}
                          >
                            Test Preparation
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Selection"}
                          >
                            Course, Country & Unversity Selection
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/application"}
                          >
                            Applications & Admissions
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Scholarship"}
                          >
                            Scholarships
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Internship"}
                          >
                            Internship
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/education-Loan"}
                          >
                            Education Loan
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Visa-Process"}
                          >
                            Visa Processing
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Alied-Services"}
                          >
                            Allied Services
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/courses"}
                      >
                        Courses
                      </Link>
                    </li>
                    {/* <li className="nav-item ">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/instructors"}
                      >
                        Instructors
                      </Link>
                    </li> */}
                    <li className="nav-item ">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/events"}
                      >
                        Upcoming Events
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/blog"}
                      >
                        Stories
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Company <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/about"}
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/News-Press"}
                          >
                            News & Press
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/Career"}
                          >
                            Careers (We are Hiring)
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="nav search-cart-bar">
                    <li className="nav-item search-box">{/* <Search /> */}</li>

                    <li className="nav-item side-box">
                      <Sidebar />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Sticky Menu */}
      <StickyMenu />
      {/* Mobile Menu */}
      <MobileMenu />
      <MainDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onOutsideClick={handleOutsideClick}
        entityDocuments={entityDocuments} // Passing documents
        activeDocumentIds={activeDocumentIds} // Selected documents
        setActiveDocumentIds={setActiveDocumentIds}
      >
        <StyledDiv>
          <div>
            {activeDocumentIds.length > 0 ? (
              <DocumentForm
                documentTypeId={activeDocumentIds}
                onCloseMainDialog={handleCloseDialog}
              />
            ) : (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: "13em",
                  }}
                >
                  <i
                    className="far fa-file-alt"
                    style={{
                      fontSize: "3.5em",
                      marginRight: "0.5em",
                      color: "#616161",
                    }}
                  ></i>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ fontSize: "3em", color: "#616161" }}
                  >
                    Please select the intake
                  </Typography>
                </Box>
              </div>
            )}
          </div>
        </StyledDiv>
      </MainDialog>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
});

export default Header;
