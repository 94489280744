import styled from "styled-components";
import { colors } from "../element/elements.js";

export const RadioButtonStyle = styled.div`
  .selection-container {
    display: flex;
    gap: 1.5em;
    margin-top: 3.55em; /* If you want to convert this to em, you can use the same method. */
  }

  .selection-button {
    padding: 0.625em 1.25em; /* Converted from 10px 20px */
    border: 0.125em solid #ccc; /* Border width converted from 2px */
    border-radius: 0.3125em; /* Converted from 5px */
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  }

  .selection-button.selected {
    background-color: #faecd0;
    border-color: #f8b400;
    color: #f8b400;
  }

  .selection-button:not(.selected):hover {
    border-color: #aaa;
  }
`;
