import React from "react";
import { FieldProps } from "formik";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Themecolors } from "./Colors";

type Option = {
  id: number;
  name: string;
};

type DropdownProps = FieldProps & {
  label: string;
  options: Option[];
};

const Dropdown: React.FC<DropdownProps> = ({
  field,
  form: { touched, errors, handleChange },
  label,
  options,
}) => (
  <FormControl
    variant="filled"
    fullWidth
    error={touched[field.name] && Boolean(errors[field.name])}
    sx={{
      "& .MuiInputLabel-root": {
        color: Themecolors.InputText_Color1,
        "&.Mui-focused": {
          color: Themecolors.InputText_Color1,
        },
      },
      "& .MuiFilledInput-root": {
        "&:after": {
          borderBottom: `2px solid ${Themecolors.Inpute_Border2}`,
        },
        "& .MuiSelect-select": {
          color: Themecolors.InputText_Color1,
        },
      },
    }}
  >
    <InputLabel>{label}</InputLabel>
    <Select {...field} label={label} onChange={handleChange}>
      {options.map((option, i) => (
        <MenuItem key={`${option.name}-${i}`} value={option.name}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
    {touched[field.name] && errors[field.name] && (
      <div style={{ color: "red" }}>{errors[field.name] as any}</div>
    )}
  </FormControl>
);

export default Dropdown;
