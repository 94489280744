import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import appStore from "../../../mobstore/MobStore";
import { api } from "../../../API/API";
import Toast from "../../common/Toast";

interface PopupFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: {
    fullName: string;
    mobileNumber: string;
    email: string;
    lookingFor: string;
    message: string;
  }) => void;
}

const PopupForm: React.FC<PopupFormProps> = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    lookingFor: "",
    message: "",
  });
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );
  const popupRef = useRef<HTMLDivElement>(null);

  const handleFormChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = appStore.loginResponse.accessToken;

    try {
      const response = await api.post("action-enroll", {
        body: {
          name: formData.fullName,
          phone: formData.mobileNumber,
          email: formData.email,
          lookingFor: formData.lookingFor,
          message: formData.message,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response) {
        setToastMessage(response.message);
        setToastSeverity("success");
        SetToastopen(true);
        setFormData({
          fullName: "",
          mobileNumber: "",
          email: "",
          lookingFor: "",
          message: "",
        });
        onClose();
      } else {
        setToastMessage("Enrollment failed. Please try again.");
        setToastSeverity("error");
        SetToastopen(true);
      }
    } catch (error) {
      console.error("Enrollment error:", error);
      setToastMessage("Enrollment failed. Please try again.");
      setToastSeverity("error");
      SetToastopen(true);
    }

    setTimeout(() => {
      SetToastopen(false);
    }, 2000);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backdropFilter: "blur(3px)",
          }}
        >
          <Card
            ref={popupRef}
            sx={{
              padding: 0,
              width: { xs: "90%", sm: "450px" },
              borderRadius: 2,
              boxShadow: 3,
              position: "relative",
            }}
          >
            <IconButton
              onClick={onClose}
              sx={{
                position: "absolute",
                right: "0.1em",
                top: "0.1em",
                color: "black",
              }}
            >
              <i className="las la-times"></i>
            </IconButton>
            <Box
              sx={{
                backgroundColor: "orange",
                padding: { xs: 1, sm: 1.5 },
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "100%",
                  color: "white",
                  fontSize: { xs: "1.5em", sm: "2em" },
                  marginBottom: 0,
                }}
              >
                Enroll Now
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} style={{ padding: "2.5em" }}>
              <TextField
                fullWidth
                margin="normal"
                name="fullName"
                placeholder="Full Name *"
                value={formData.fullName}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                name="mobileNumber"
                placeholder="Mobile Number *"
                value={formData.mobileNumber}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                name="email"
                placeholder="Email Address *"
                value={formData.email}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
              <TextField
                fullWidth
                select
                margin="normal"
                name="lookingFor"
                value={formData.lookingFor}
                onChange={handleFormChange}
                required
                variant="outlined"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Looking For*</option>
                <option value="study-in-UK">Study in UK</option>
                <option value="study-in-USA">Study in USA</option>
                <option value="study-in-Canada">Study in Canada</option>
              </TextField>
              <TextField
                fullWidth
                margin="normal"
                name="message"
                placeholder="Enter Your Message *"
                value={formData.message}
                onChange={handleFormChange}
                required
                variant="outlined"
                multiline
                rows={4}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 2, height: "3em" }}
              >
                Send Message
              </Button>
            </form>
          </Card>
        </Box>
      </Modal>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export default PopupForm;
