import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "./styles/sidebar.js";

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const sidebarBtn = document.getElementById("sidebar-btn");

    if (sidebarBtn) {
      const sidebarOverlay = document.getElementById("sidebar-overlay");
      const sidebarBody = document.getElementById("sidebar-body");
      const sidebarExit = document.getElementById("close-sidebar");

      const openSidebar = () => {
        document.body.style.overflow = "hidden"; // Disable main page scroll
        sidebarOverlay.classList.add("visible");
        sidebarBody.classList.add("opened");
        setSidebarOpen(true);
      };

      const closeSidebar = () => {
        document.body.style.overflow = ""; // Enable main page scroll
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        setSidebarOpen(false);
      };

      sidebarBtn.addEventListener("click", (e) => {
        e.preventDefault();
        if (!sidebarOpen) {
          openSidebar();
        } else {
          closeSidebar();
        }
      });

      sidebarOverlay.addEventListener("click", (e) => {
        e.preventDefault();
        if (sidebarOpen) {
          closeSidebar();
        }
      });

      sidebarExit.addEventListener("click", (e) => {
        e.preventDefault();
        if (sidebarOpen) {
          closeSidebar();
        }
      });
    }
  }, [sidebarOpen]);

  return (
    <Styles>
      {/* Sidebar */}
      <a
        href={process.env.PUBLIC_URL + "/"}
        className="nav-link nav-sidebar"
        id="sidebar-btn"
      >
        <i className="las la-bars"></i>
      </a>

      <div className="sidebar" id="sidebar-body">
        <div className="side-logo d-flex justify-content-between">
          <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/GradWalkLogo.png"}
                alt=""
              />
            </Link>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="side-content">
          <h5>About Us</h5>
          <div className="about-text">
            <p>
              Grad Walk LLP is a leading study abroad consultancy with over 14
              years of experience, providing services to students and parents
              for destinations like the UK, Ireland, Australia, Canada, New
              Zealand, and the USA. We are affiliated with top colleges and
              universities worldwide.
            </p>
            <p>
              We also offer higher education management consultancy, providing
              strategic and operational support to international universities.
              Our CEO, Mr. Sridhar Palthi, founded Grad Walk LLP in 2008 to
              offer unbiased guidance to students. His expertise includes
              research, strategy, and market intelligence.
            </p>
          </div>
        </div>
        {/* <div className="side-post">
          <h5>Recent Post</h5>
          <div className="post-box d-flex">
            <div className="post-img">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/post-01.jpg"}
                alt=""
              />
            </div>
            <div className="post-title">
              <p>Lorem ipsum dolor sit amet, consecte adipisicing elit.</p>
              <span>March 12, 2020</span>
            </div>
          </div>
          <div className="post-box d-flex">
            <div className="post-img">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/post-02.jpg"}
                alt=""
              />
            </div>
            <div className="post-title">
              <p>Lorem ipsum dolor sit amet, consecte adipisicing elit.</p>
              <span>March 12, 2020</span>
            </div>
          </div>
          <div className="post-box d-flex">
            <div className="post-img">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/post-03.jpg"}
                alt=""
              />
            </div>
            <div className="post-title">
              <p>Lorem ipsum dolor sit amet, consecte adipisicing elit.</p>
              <span>March 12, 2020</span>
            </div>
          </div>
        </div> */}
        {/* <div className="side-gallery">
          <h5>Gallery</h5>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-01.jpg"}
            alt=""
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-02.jpg"}
            alt=""
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-03.jpg"}
            alt=""
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-04.jpg"}
            alt=""
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-05.jpg"}
            alt=""
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gallery-06.jpg"}
            alt=""
          />
        </div> */}
        <div className="side-contact">
          <h5>Contact Us</h5>
          <ul className="list-unstyled">
            <li>
              <i className="las la-map-marker"></i>108, Topaz Plaza, Panjagutta,
              Hyderabad, Telangana, 500082
            </li>
            <li>
              <i className="las la-phone"></i>+91 9052378789
            </li>
            <li>
              <i className="las la-envelope"></i> help@gradwalk.in
            </li>
          </ul>
        </div>
        <div className="side-social">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a
                target="_bank"
                href={
                  process.env.PUBLIC_URL +
                  "https://www.facebook.com/nxtstepoverseaseducation"
                }
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            {/* <li className="list-inline-item">
              <a
                target="_bank"
                href={process.env.PUBLIC_URL + "https://x.com/twitt_login"}
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li> */}
            <li className="list-inline-item">
              <a
                target="_bank"
                href={process.env.PUBLIC_URL + "https://wa.me/7396857905"}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                target="_blank"
                href={
                  process.env.PUBLIC_URL +
                  "https://www.linkedin.com/company/nxts-step-overseas-education/"
                }
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                target="_blank"
                href={
                  process.env.PUBLIC_URL +
                  "https://www.instagram.com/nxtstepoverseaseducation/"
                }
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar-overlay" id="sidebar-overlay"></div>
    </Styles>
  );
}

export default Sidebar;
