import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .event-page {
    .event-page-area {
      padding: 70px 0;
      .event-box {
        margin-bottom: 30px;
        .event-img {
          a {
            img {
              border-radius: 5px;
            }
          }

          @media (max-width: 991px) {
            display: none;
          }
        }
        .event-content {
          position: relative;
          .content-box {
            box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
            padding: 20px;
            background: #ffffff;
            border-radius: 5px;
            position: absolute;
            top: 22px;
            left: -9%;
            z-index: 1;
            .event-title {
              h6 {
                margin-bottom: 10px;
                a {
                  color: ${colors.E_text1};
                  font-weight: 600;

                  &:hover {
                    color: ${colors.E_text5};
                  }
                }
              }
            }

            .event-time-location {
              margin-bottom: 10px;
              ul {
                li {
                  font-size: 13px;
                  color: ${colors.E_text2};
                  i {
                    font-size: 18px;
                    color: ${colors.E_icon1};
                    vertical-align: top;
                    margin-right: 6px;
                    margin-bottom: 7px;
                  }
                  &:first-child {
                    margin-right: 20px;
                  }
                }
              }
            }

            .event-desc {
              p {
                font-size: 13px;
                color: ${colors.E_text3};
                line-height: 20px;
              }

              @media (max-width: 767px) {
                margin-bottom: 10px;
              }
            }

            .event-date {
              position: relative;
              margin-bottom: 20px;
              padding-top: 6px;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.E_border2};
                width: 1px;
                height: 100px;
                top: 0;
                left: -30px;
                @media (max-width: 767px) {
                  content: none;
                }
              }
              p {
                font-size: 20px;
                color: ${colors.E_text5};
                font-weight: 500;
                text-transform: uppercase;

                @media (max-width: 767px) {
                  float: left;
                  font-size: 18px;
                  margin-right: 20px;
                  margin-bottom: 10px;
                }
              }

              @media (max-width: 767px) {
                margin-bottom: 0;
                padding-top: 0;
              }
            }

            .join-btn {
              a {
                font-size: 12px;
                color: ${colors.E_Button_text1};
                border: 1px solid ${colors.E_Button_border2};
                font-weight: 500;
                text-transform: uppercase;
                padding: 9px 12px 7px;
                border-radius: 5px;
                &:hover {
                  color: #ffffff;
                  background: ${colors.E_Button_hv1};
                  border-color: ${colors.E_Button_border1};
                }

                @media (max-width: 767px) {
                  float: left;
                  padding: 5px 10px 3px;
                }
              }
            }

            @media (max-width: 991px) {
              position: unset;
            }
          }
        }
      }

      ul.pagination-box {
        margin-top: 50px;
      }

      .course-sidebar {
        @media (max-width: 991px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        padding: 40px 0 35px;
      }
    }
  }  
                  .copytext-area {
                  ul.social {
                li {
                  margin-right: 30px; 
                  margin-top:10px;
                    a {
                        text-align: center;
                        position  : relative;
                        height    : 16.5px;
                        display   : inline-block;

                        &:before {
                            content           : "";
                            position          : absolute;
                            border-width      : 9.5px 17px;
                            border-style      : solid;
                            border-top-color  : transparent;
                            border-right-color: transparent;
                            border-left-color : transparent;
                            top               : -18px;
                            left              : 0;
                            z-index           : 1;
                            transition : all 0.2s ease;
                        }

                        &:after {
                            content            : "";
                            position           : absolute;
                            border-width       : 9px 17px;
                            border-style       : solid;
                            border-right-color : transparent;
                            border-bottom-color: transparent;
                            border-left-color  : transparent;
                            bottom             : -18px;
                            left               : 0;
                            z-index            : 1;
                            transition : all 0.2s ease;
                        }

                        i {
                            font-size: 14px;
                            color    : #ffffff;
                            width    : 34px;
                        }

                        &:hover {
                            background-color: ${colors.E_icon1} !important;

                            &:before {
                                border-bottom-color: ${colors.E_icon1} !important;
                            }

                            &:after {
                                border-top-color: ${colors.E_icon1} !important;
                            }
                        }
                    }

                    &:nth-child(1) {
                        a {
                            background-color: #4267B2;

                            &:before {
                                border-bottom-color: #4267B2;
                            }

                            &:after {
                                border-top-color: #4267B2;
                            }
                        }
                    }

                    &:nth-child(2) {
                        a {
                            background-color: #1DA1F2;

                            &:before {
                                border-bottom-color: #1DA1F2;
                            }

                            &:after {
                                border-top-color: #1DA1F2;
                            }
                        }
                    }

                    &:nth-child(3) {
                        a {
                            background-color: #2867B2;

                            &:before {
                                border-bottom-color: #2867B2;
                            }

                            &:after {
                                border-top-color: #2867B2;
                            }
                        }
                    }

                    &:nth-child(4) {
                        a {
                            background-color: #DD1343;

                            &:before {
                                border-bottom-color: #DD1343;fgap
                            }

                            &:after {
                                border-top-color: #DD1343;
                            }
                        }
                    }

                 }
            }
        }

            @media(max-width: 767px) {
                padding: 30px 0;
            }
        }
    }
`;
