import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../onlinecounselling/CounsellingStyle";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import { api } from "../../../API/API";
import appStore from "../../../mobstore/MobStore";
import Toast from "../../common/Toast";

function Counselor() {
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );
  const history = useHistory();

  useEffect(() => {
    const form = document.getElementById("form_registration");
    form?.addEventListener("submit", formSubmit);

    return () => {
      form?.removeEventListener("submit", formSubmit);
    };
  }, []);

  async function formSubmit(e: any) {
    e.preventDefault();

    const fname = document.getElementById(
      "registration_user"
    ) as HTMLInputElement;

    const email = document.getElementById(
      "registration_email"
    ) as HTMLInputElement;

    const password = document.getElementById(
      "registration_password"
    ) as HTMLInputElement;

    const fnameValue = fname?.value.trim();
    const emailValue = email?.value.trim();
    const passwordValue = password?.value.trim();

    let isValid = true;

    if (fnameValue === "") {
      setError(fname, "Name can't be blank");
      isValid = false;
    }

    if (emailValue === "") {
      setError(email, "Email can't be blank");
      isValid = false;
    } else if (!isEmail(emailValue)) {
      setError(email, "Not a valid email");
      isValid = false;
    }

    if (passwordValue === "") {
      setError(password, "Password can't be blank");
      isValid = false;
    } else if (passwordValue.length < 6) {
      setError(password, "Password should be at least 6 characters long");
      isValid = false;
    }

    if (isValid) {
      const token = appStore.loginResponse.accessToken;
      try {
        const response = await api.post("gradwalk-counselor", {
          body: {
            name: fnameValue,
            email: emailValue,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response && response.success) {
          setToastMessage("Your message is successfully submitted.");
          setToastSeverity("success");
          SetToastopen(true);
        } else {
          setToastMessage("Submission failed. Please try again.");
          setToastSeverity("error");
          SetToastopen(true);
        }
      } catch (error) {
        console.error("Error during submission:", error);
        setToastMessage("Submission failed. Please try again.");
        setToastSeverity("error");
        SetToastopen(true);
      }
    }
  }

  function setError(input: any, message: any) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".registration_input-msg");
    formControl.className = "form-control text-left error";
    errorMsg.innerText = message;

    input.addEventListener("input", function handleInput() {
      formControl.classList.remove("error");
      errorMsg.innerText = "";
      input.removeEventListener("input", handleInput);
    });
  }
  function handleBlur(e: any) {
    const input = e.target;
    const inputValue = input.value.trim();

    if (inputValue === "") {
      input.classList.add("touched");
      setError(input, `${input.name} can't be blank`);
    } else if (input.id === "registration_email") {
      if (!isEmail(inputValue)) {
        setError(input, "Not a valid email");
      }
    } else if (input.id === "registration_password") {
      if (inputValue.length < 6) {
        setError(input, "Password should be at least 6 characters long");
      }
    } else if (input.id === "registration_cpassword") {
      const password = document.getElementById(
        "registration_password"
      ) as HTMLInputElement;
      const passwordValue = password.value.trim();

      if (inputValue !== passwordValue) {
        setError(input, "Passwords do not match");
      }
    }
  }

  function isEmail(email: any) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper registration-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Registration" />

        {/* Registration Area */}
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Become GradWalk Counselor</h3>
                  </div>
                  <form id="form_registration" className="form">
                    <p className="form-control">
                      <label htmlFor="registration_user">User Name</label>
                      <input
                        type="text"
                        placeholder="Username"
                        id="registration_user"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>

                    <p className="form-control">
                      <label htmlFor="registration_email">Email Address</label>
                      <input
                        type="email"
                        placeholder="Email address"
                        id="registration_email"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>

                    <p className="form-control">
                      <label htmlFor="registration_password">Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="registration_password"
                        onBlur={handleBlur}
                      />
                      <span className="registration_input-msg"></span>
                    </p>

                    <button>Register Now</button>
                    <button type="button" onClick={() => history.push("/")}>
                      Go Back
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>{" "}
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
}

export default Counselor;
