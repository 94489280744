import styled from "styled-components";
import { colors } from "./../../../common/element/elements.js";

export const LoadingStyles = styled.div`
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative; /* Add this line */
    background-color: #efefef;
    height: 400px;
    background: ${colors.bg1};
  }

  .loading-icon {
    font-size: 3rem;
    animation: bounce 1.5s infinite;
    position: relative; /* Add this line */
    z-index: 1; /* Add this line */
    color: white;
  }

  .loading-icon::before {
    content: "\f15b"; /* Unicode for the search icon in FontAwesome */
    font-family: "Font Awesome 5 Free"; /* Ensure you are using the correct font family */
    font-weight: 900; /* FontAwesome solid style */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7rem; /* Adjust size as needed */
    opacity: 0.1; /* Adjust opacity to make it a background */
    z-index: -1; /* Ensure it is behind the loading icon */
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  @keyframes smoothBounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  .loading-text {
    margin-top: 20px;
    font-size: 1.5rem;
    animation: smoothBounce 1.5s infinite;
    color: white;
  }
`;
