import React from "react";
import { BreadcrumbBox } from "./Breadcrumb";
import { Box, Container, Typography, Button } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { colors } from "./element/elements";

function NoDataFound() {
  const handleGoBack = () => {
    window.history.back(); // Navigates to the previous page
  };

  return (
    <div className="main-wrapper course-page">
      <BreadcrumbBox title="Student Success Stories" />
      <section className="course-grid-area">
        <Container>
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              border: "1px solid #9e9e9e",
              borderRadius: "1em",
              margin: "5em",
              padding: "5em",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%", marginTop: 2 }}
            >
              <WarningIcon
                style={{
                  fontSize: "4vw",
                  fontWeight: 500,
                  color: "#757575",
                  marginRight: "10px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  marginTop: "15px",
                  fontWeight: 450,
                  fontSize: "2vw",
                  color: "#757575",
                }}
              >
                No courses found
              </Typography>
            </Box>

            <Button
              variant="contained"
              onClick={handleGoBack}
              sx={{
                marginTop: "2em",
                paddingLeft: "2.3em",
                width: "11em",
                color: "#fff",
                background: colors.gr_bg,
                border: "1px solid #ffab40",
                borderRadius: "5px",
                textTransform: "uppercase",
                marginBottom: "20px",
                "&:hover": {
                  background: colors.gr_bg2,
                  "& i": {
                    color: "#ffffff",
                  },
                },
                "@media(max-width: 575px)": {
                  fontSize: "15px",
                  height: "40px",
                },
              }}
            >
              Go Back
            </Button>
          </Box>
        </Container>
      </section>
    </div>
  );
}

export default NoDataFound;
