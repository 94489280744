import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { TabBoxStyles } from "./TabBoxStyle";
import { BreadcrumbBox } from "../../common/Breadcrumb";

class NewsPressTabBox extends Component {
  render() {
    return (
      <TabBoxStyles>
        {/* News & Press Tab Box Area */}
        <BreadcrumbBox title="News & Press" />
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="news">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="news">
                        <i className="las la-arrow-right"></i> Latest News
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="press">
                        <i className="las la-arrow-right"></i> Press Releases
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="news">
                      <h4 className="tab-title">Latest News</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            NextStep Overseas Education Unveils Exciting
                            Expansion Plans Across Southeast Asia{" "}
                          </h6>
                          Open the gateway to opportunities with NextStep
                          Overseas Education! Thrilled to announce: our programs
                          ignite your academic journey for global success. Dare
                          to dream big as we empower students across Southeast
                          Asia. Your future knows no limits with NextStep."
                        </li>
                        <li>
                          <h6 className="title">
                            NextStep Overseas Education Honored as Top
                            Consultancy Firm at Global Awards
                          </h6>
                          Join the Elite: NextStep Overseas Education Leads the
                          Way in Academic Consultancy Elevate Your Educational
                          Journey: NextStep Overseas Education Sets the Standard
                          for Excellence! Success Starts Here: NextStep Overseas
                          Education Guides Students Towards Brighter Futures!
                        </li>
                        <li>
                          <h6 className="title">
                            NextStep Overseas Education Partners with Premier
                            Universities Worldwide for Student Success{" "}
                          </h6>
                          Discover your academic dreams with NextStep Overseas
                          Education's elite university partnerships. Elevate
                          your future with premier institutions worldwide.
                          Explore endless opportunities for success and growth.
                          Join us on your journey to academic excellence!"
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="press">
                      <h4 className="tab-title">Press Releases</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <h6 className="title">
                            NextStep Consultancy Overseas Partners with Leading
                            Universities to Expand Educational Opportunities
                          </h6>
                          NextStep Consultancy Overseas proudly announces
                          pioneering partnerships with world-class universities,
                          setting a new standard in global education
                          consultancy. These collaborations unlock unparalleled
                          opportunities for students, delivering access to
                          prestigious academic programs and immersive learning
                          environments. By aligning with these esteemed
                          institutions, NextStep reaffirms its commitment to
                          shaping the future of education and empowering
                          students to excel on a global stage.
                        </li>
                        <li>
                          <h6 className="title">
                            NextStep Consultancy Overseas Unveils Cutting-Edge
                            EdTech Platform Redefining Study Abroad Dynamics
                          </h6>
                          NextStep Consultancy Overseas introduces an innovative
                          EdTech platform, revolutionizing the study abroad
                          landscape with seamless integration and personalized
                          support. This state-of-the-art platform redefines the
                          student experience, offering streamlined processes and
                          comprehensive resources for navigating the
                          complexities of international education. With a focus
                          on accessibility and empowerment, NextStep empowers
                          students to realize their academic aspirations with
                          confidence and clarity.
                        </li>
                        <li>
                          <h6 className="title">
                            NextStep Consultancy Overseas Marks Landmark
                            Anniversary, Reflecting on a Decade of Empowering
                            Global Minds
                          </h6>
                          NextStep Consultancy Overseas celebrates a decade of
                          transformative impact, empowering students to embark
                          on enriching educational journeys across borders.
                          Since its inception, NextStep has been a beacon of
                          guidance, assisting countless students in navigating
                          the complexities of international education with
                          precision and care. As NextStep embarks on the next
                          chapter of its journey, it remains steadfast in its
                          commitment to nurturing global citizens and shaping a
                          brighter tomorrow.
                        </li>
                      </ul>{" "}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </TabBoxStyles>
    );
  }
}

export default NewsPressTabBox;
