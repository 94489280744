import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/popularCourse.js";

interface Course {
  courseLink: string;
  University: string;
  TuitionFee: string;
}
interface categoryProps {
  courses: Course[];
}

const PopularCourse: React.FC<categoryProps> = ({ courses }) => {
  const coursesByUniversity = new Map<string, any>();
  courses.forEach((course: any) => {
    const existingCourse = coursesByUniversity.get(course.University);
    console.log(course);
    const courseFee = parseFloat(
      course.TuitionFeePerYear.replace(/[^0-9.-]+/g, "")
    );
    const existingFee = existingCourse
      ? parseFloat(existingCourse.TuitionFeePerYear.replace(/[^0-9.-]+/g, ""))
      : 0;

    if (!existingCourse || courseFee > existingFee) {
      coursesByUniversity.set(course.UniversityName, course);
    }
  });

  // Convert the map values to  array
  const uniqueCourses = Array.from(coursesByUniversity.values());

  // descending order
  uniqueCourses.sort((a, b) => {
    const feeA = parseFloat(a.TuitionFeePerYear.replace(/[^0-9.-]+/g, ""));
    const feeB = parseFloat(b.TuitionFeePerYear.replace(/[^0-9.-]+/g, ""));
    return feeB - feeA;
  });

  return (
    <Styles>
      {/* Popular Course */}
      <div className="popular-course">
        <h5>Popular Course</h5>
        <div className="popular-items">
          {uniqueCourses.map((data, i) => (
            <div className="item-box d-flex" key={i}>
              <div className="item-img">
                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/pop-course-1.jpg`
                    }
                    alt=""
                  />
                </Link>
              </div>
              <div className="item-content">
                <p className="title">
                  <Link to={process.env.PUBLIC_URL + data.courseLink}>
                    {data.University}
                  </Link>
                </p>
                {/* <ul className="list-unstyled list-inline rating">
                  <li className="list-inline-item">
                    <i className="las la-star"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-star"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-star"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-star"></i>
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-star"></i>
                  </li>
                </ul> */}
                <p className="price">{data.TuitionFeePerYear} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default PopularCourse;
