import React, { Component } from "react";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import { Container, Row, Col } from "react-bootstrap";
import Data from "../../../data/services/educationLoan.json";
import { ServiceStyle } from "./ServiceStyle";
import AboutUs from "../../About";

class educationLoan extends Component {
  render() {
    return (
      <ServiceStyle>
        <div className="main-wrapper blog-details-page">
          <BreadcrumbBox title="Education Loan" />
          <section className="blog-details-area">
            <Container>
              <Row>
                <Col lg="12" md="10" sm="7">
                  <div className="blog-details-box">
                    {Data.descriptions.map((items, index) => (
                      <div className="blog-details-box" key={index}>
                        <div className="heading">
                          <h4>{items.title}</h4>
                          <div className="blog-details-desc">
                            <p>{items.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>{" "}
          <AboutUs />
        </div>
      </ServiceStyle>
    );
  }
}

export default educationLoan;
