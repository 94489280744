import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/newsletterForm.js";
import { api } from "../API/API";
import appStore from "../mobstore/MobStore.jsx";
import Toast from "./common/Toast";

function NewsletterForm() {
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );

  let data = {
    secTitle: "Get The Latest News & Updates On Your Box",
  };

  useEffect(() => {
    const form = document.getElementById("form2");
    const name = document.getElementById("name2");
    const email = document.getElementById("email2");

    form?.addEventListener("submit", formSubmit);

    async function formSubmit(e: Event) {
      e.preventDefault();
      //@ts-ignore
      const nameValue = name.value.trim();
      //@ts-ignore
      const emailValue = email.value.trim();

      let haserror = false;

      if (nameValue === "") {
        setError(name, "Name can't be blank");
        haserror = true;
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
        haserror = true;
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
        haserror = true;
      } else {
        setSuccess(email);
      }

      if (!haserror) {
        try {
          const response = await api.post("action-subscribe", {
            body: { name: nameValue, email: emailValue },
          });
          if (response.message === "Subscription added successfully.") {
            setToastMessage(response.message);
            setToastSeverity("success");
            SetToastopen(true);
          } else {
            setToastMessage(response.message);
            setToastSeverity("error");
            SetToastopen(true);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }

    function setError(input: any, message: string) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".input-msg2");
      formControl.className = "form-control error";
      errorMsg.innerText = message;
    }

    function setSuccess(input: any) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email: string) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }

    return () => {
      form?.removeEventListener("submit", formSubmit);
    };
  }, []);

  return (
    <Styles>
      {/* Newsletter Form */}
      <section className="newsletter-form-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="newsletter-container">
                <div className="newsletter-box">
                  <div className="sec-title text-center">
                    <h4>{data.secTitle}</h4>
                  </div>
                  <form id="form2" className="form">
                    <Row>
                      <Col md="4">
                        <p className="form-control">
                          <input
                            type="text"
                            placeholder="Enter your Name"
                            id="name2"
                          />
                          <span className="input-msg2"></span>
                        </p>
                      </Col>
                      <Col md="4">
                        <p className="form-control">
                          <input
                            type="email"
                            placeholder="Enter your Email"
                            id="email2"
                          />
                          <span className="input-msg2"></span>
                        </p>
                      </Col>
                      <Col md="4">
                        <button>
                          <i className="las la-envelope"></i>Subscribe Now
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
}

export default NewsletterForm;
