import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Badge,
} from "@material-ui/core";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import LoadingComponent from "./components/LoadingComponent";
import SingleSelectionComponent from "../../common/radiobutton/SingleSelectionComponent";
import { RadioButtonStyle } from "../../common/radiobutton/RadioButton";
import { api } from "../../../API/API";
import { useHistory } from "react-router-dom";
import { AnyARecord } from "dns";
import Data from "../../../components/pages/Courses/styles/collegedata.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(5),
      border: "0px",
    },
    fieldContainer: {
      border: "0px",
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "rgb(22 55 90)",
      color: "white",
      height: "3.8em",
    },

    buttonContainer: {
      marginTop: theme.spacing(2),
      border: "0px",
      display: "flex",
      flexDirection: "row-reverse",
      padding: theme.spacing(2),
      justifyContent: "space-between",
      borderTop: "1px solid #dedede",
      fontFamily: "sans-serif-italic",
    },
    stepTitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    stepIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#ffab40",
      marginRight: theme.spacing(1),
      color: "white",
    },
  })
);
const useButtonstyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#ffab40",
      "&:hover": {
        background:
          "linear-gradient(90deg, rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)",
        border: "1px solid #ffab40",
      },
    },
  })
);

const useStepLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: "1.2rem", // Adjust the font size as needed
      color: "white",
    },
  })
);
interface DataType {
  title: string;
  description: string;
  columnType: number;
  details: Detail[];
}
interface Detail {
  name: string;
  value: string;
  isFilter: boolean;
  dataType: string;
  selections: { [key: string]: string };
  fieldType: number;
  trigger: TriggerType | null;
}
interface TriggerType {
  id: number;
  type: string;
  value: string;
  compareId: string;
}

const validationSchemas = [
  Yup.object({
    StudyLevelId: Yup.number().required("Required"),
    Country: Yup.string().required("Required"),
    Concentration: Yup.string().required("Required"),
  }),
  Yup.object({
    College: Yup.string().required("Required"),
    Course: Yup.string().required("Required"),
    Duration: Yup.string().required("Required"),
    Locations: Yup.string().required("Required"),
    Backlog: Yup.string().required("Required"),
  }),
  Yup.object({
    IeltsOverall: Yup.number().required("Required"),
    ReadingScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),

    WritingScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),

    ListeningScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),
    SpeakingScore: Yup.number()
      .required("Required")
      .test("is-valid-Score", function (values) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };
        const thresholds: { [keys: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };
        if (IeltsOverall !== undefined && typeof values === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (values > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }
        return true;
      }),

    OverallScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),
    OtherScore: Yup.number().required("Required"),
    Gmatscore: Yup.number().required("Required"),
    GreScore: Yup.number().required("Required"),
    DETScore: Yup.number().required("Required"),
    CGPAOrPercentage: Yup.number().required("Required"),
  }),
  Yup.object({
    ApplicationFee: Yup.number().required("Required"),
    TuitionFee: Yup.number().required("Required"),
    I20DepositFee: Yup.number().required("Required"),
  }),
];
interface CollegeFinderProps {
  onSearchResult: (result: any) => void;
}
const CollegeFinder: React.FC<CollegeFinderProps> = ({ onSearchResult }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  const data: DataType[] = Data as DataType[];
  const steps = data.map((step) => step.title);

  const classes = useStyles();
  const stepLabelClasses = useStepLabelStyles();
  const buttonStyles = useButtonstyle();
  const history = useHistory();
  const formik: any = useFormik({
    initialValues: {
      Backlog: 0,
      Name: "",
      StudyLevelId: "",
      Country: "",
      Location: "",
      Concentration: "",
      College: "",
      Course: "",
      Duration: "",
      Locations: "",
      Marks1stYear: "",
      Marks2ndYear: "",
      WorkExp: "",
      BachelorsProgram: "",
      IeltsOverall: "1",
      ToeflScore: 0,
      ActScore: 0,
      GreScore: 0,
      DETScore: 0,
      CGPAOrPercentage: 0,
      ApplicationFee: "",
      TuitionFee: "",
      I20DepositFee: "",
      AptitudeTest: "1",
      Gmatscore: 0,
      ReadingScore: 0,
      WritingScore: 0,
      ListeningScore: 0,
      SpeakingScore: 0,
      OverallScore: 0,
      OtherScore: 0,
    },
    validationSchema: validationSchemas[activeStep],
    onSubmit: async (values: any) => {
      // console.log("Form values ==", values);
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getFiltervalues = (values: any, initialValues: any) => {
    return Object.keys(values).reduce((acc: any, key) => {
      if (values[key] !== initialValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
  };

  const handleSubmit = async () => {
    setShowLoading(true);
    try {
      // const filteredValues = getFiltervalues(
      //   formik.values,
      //   formik.initialValues
      // );

      const response = await api.post(`search-details`, {
        body: {
          dataTableId: formik.values["Country"],
          search: formik.values,
        },
      });
      if (response.data.length === 0) {
        history.push("no-courses-found");
        setShowLoading(true);
        return;
      }
      if (response.status) {
        onSearchResult(response.data);
        setShowLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleselectionvalue = (value: any, fieldName: string) => {
    formik.setFieldValue(fieldName, value);
  };

  const shouldFieldBeVisible = (trigger: TriggerType | null) => {
    if (!trigger) return true;

    const currentVales = formik.values[trigger.compareId];
    const selectedvalues = trigger.value.split(",");
    return selectedvalues.includes(currentVales);
  };

  if (showLoading) return <LoadingComponent />;

  return (
    <FormikProvider value={formik}>
      <div style={{ border: "1px solid #dedede" }}>
        <Grid
          className={classes.fieldContainer}
          container
          justifyContent="space-between"
        >
          <div className={classes.stepTitleContainer}>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                marginLeft: "2.187em",
                paddingTop: "0.563em",
                fontWeight: 400,
                color: "white",
                fontSize: "1.31em",
              }}
            >
              {steps[activeStep]}
            </Typography>
            <div style={{ display: "flex" }}>
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={classes.stepIcon}
                  style={{
                    backgroundColor:
                      index === activeStep ? "#ffab40" : "transparent",
                  }}
                >
                  {index + 1}
                </div>
              ))}
            </div>{" "}
          </div>
        </Grid>
        <Form>
          <Paper className={classes.formContainer} variant="outlined">
            <Typography variant="h6" gutterBottom>
              {Data[activeStep].description}
            </Typography>

            <Grid container spacing={3}>
              {data[activeStep].details
                .filter((detail) => shouldFieldBeVisible(detail.trigger))
                .map((detail) => (
                  <Grid
                    item
                    xs={12}
                    sm={
                      detail.fieldType === 6 &&
                      shouldFieldBeVisible(detail.trigger)
                        ? 6
                        : data[activeStep].columnType === 2
                        ? 12
                        : 6
                    }
                    key={detail.value}
                  >
                    {detail.fieldType === 1 && !detail.trigger && (
                      <TextField
                        id={detail.value}
                        name={detail.value}
                        label={detail.name}
                        fullWidth
                        value={formik.values[detail.value]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[detail.value] &&
                          Boolean(formik.errors[detail.value])
                        }
                        helperText={
                          formik.touched[detail.value] &&
                          formik.errors[detail.value]
                        }
                      />
                    )}
                    {detail.fieldType === 8 && !detail.trigger && (
                      <FormControl fullWidth>
                        <InputLabel>{detail.name}</InputLabel>
                        <Select
                          id={detail.value}
                          name={detail.value}
                          value={formik.values[detail.value]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[detail.value] &&
                            Boolean(formik.errors[detail.value])
                          }
                        >
                          {Object.entries(detail.selections).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                    {detail.fieldType === 9 && (
                      <FormControl fullWidth>
                        <InputLabel>{detail.name}</InputLabel>
                        <RadioButtonStyle>
                          <SingleSelectionComponent
                            options={detail.selections as any}
                            onChange={(value) => {
                              handleselectionvalue(value, detail.value);
                            }}
                          />
                        </RadioButtonStyle>
                      </FormControl>
                    )}

                    {detail.fieldType === 6 &&
                      shouldFieldBeVisible(detail.trigger) && (
                        <TextField
                          id={detail.value}
                          name={detail.value}
                          label={detail.name}
                          type="number"
                          fullWidth
                          value={formik.values[detail.value]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[detail.value] &&
                            Boolean(formik.errors[detail.value])
                          }
                          helperText={
                            formik.touched[detail.value] &&
                            formik.errors[detail.value]
                          }
                        />
                      )}
                  </Grid>
                ))}
            </Grid>
          </Paper>
          <div className={classes.buttonContainer}>
            {activeStep !== steps.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                className={buttonStyles.button}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                className={buttonStyles.button}
              >
                Submit
              </Button>
            )}

            {activeStep !== 0 && (
              <Button
                className={buttonStyles.button}
                type="button"
                variant="contained"
                onClick={handleBack}
                style={{ marginRight: "8px" }}
              >
                Back
              </Button>
            )}
          </div>
        </Form>
      </div>
    </FormikProvider>
  );
};

export default CollegeFinder;
