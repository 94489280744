import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .newsletter-form-area {
    background-color: ${colors.bg1};

    .newsletter-container {
      position: relative;

      .newsletter-box {
        background: ${colors.gr_bg};
        padding: 2.3125em 2.5em 2.5em; /* 37px 40px 40px */
        border-radius: 0.3125em; /* 5px */
        position: absolute;
        width: 100%;
        top: -2.35em; /* -20px */
        left: 0;
        z-index: 2;

        .sec-title {
          h4 {
            color: #ffffff;
            margin-bottom: 2.1875em; /* 35px */
            font-size: 1.375em; /* 22px */

            @media (max-width: 35em) {
              /* max-width: 575px */
              margin-bottom: 0.9375em; /* 15px */
              font-size: 1.25em; /* 20px */
            }
          }
        }

        form.form {
          p.form-control {
            padding: 0;
            width: 100%;
            max-width: 30em; /* Set a maximum width for the form controls */
            height: auto;
            background: transparent;
            border: none;
            position: relative;

            input {
              width: 100%;
              height: 3.125em; /* 50px */
              background-color: #ffffff;
              font-size: 0.875em; /* 14px */
              padding: 0.9375em 1.25em; /* 15px 20px */
              color: ${colors.black1};
              border: none;
              border-radius: 0.3125em; /* 5px */

              &::placeholder {
                font-size: 0.875em; /* 14px */
                font-style: italic;
                color: ${colors.bg1};
              }

              @media (max-width: 30em) {
                /* max-width: 480px */
                height: 2.625em; /* 42px */
                padding: 0.9375em; /* 15px */
              }
            }

            span {
              color: #ffffff;
              font-weight: 300;
              position: absolute;
              bottom: -1.375em; /* -22px */
              left: 0;
              visibility: hidden;
            }

            @media (max-width: 48em) {
              /* max-width: 767px */
              margin-bottom: 0.65em; /* 25px */
            }
          }

          p.form-control.success {
            input {
              border: 0.125em solid ${colors.green}; /* 2px */
            }

            &::before {
              position: absolute;
              content: "\f058";
              font-family: "Line Awesome Free";
              font-size: 1.5em; /* 24px */
              color: ${colors.green};
              font-weight: 900;
              top: 0.5em; /* 8px */
              right: 0.625em; /* 10px */
            }
          }

          p.form-control.error {
            input {
              border: 0.125em solid ${colors.red}; /* 2px */
            }

            &::before {
              position: absolute;
              content: "\f06a";
              font-family: "Line Awesome Free";
              font-size: 1.5em; /* 24px */
              color: ${colors.red};
              font-weight: 900;
              top: 0.5em; /* 8px */
              right: 0.625em; /* 10px */
            }
          }

          p.form-control.error {
            span {
              visibility: visible;
            }
          }

          button {
            font-size: 0.875em; /* 14px */
            color: #fff;
            background: ${colors.bg1};
            opacity: 0.92;
            width: 100%;
            height: 3.125em; /* 50px */
            font-weight: 500;
            border: none;
            border-radius: 0.3125em; /* 5px */
            text-transform: uppercase;

            i {
              font-size: 1.375em; /* 22px */
              color: ${colors.green};
              margin-right: 0.3125em; /* 5px */
              vertical-align: text-bottom;
              line-height: 1.1875em; /* 19px */
            }

            &:hover {
              background: ${colors.gr_bg3};

              i {
                color: #ffffff;
              }
            }

            @media (max-width: 30em) {
              /* max-width: 480px */
              height: 2.625em; /* 42px */
            }
          }
        }

        @media (max-width: 48em) {
          /* max-width: 767px */
          padding: 0.7em; /* 25px 35px 35px */
        }
      }
    }
  }
`;
