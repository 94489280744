import React, { useEffect, useLayoutEffect } from "react";
import Datas from "../data/footer/footer.json";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerTwo.js";

function Footer() {
  const location = useLocation();
  useEffect(() => {
    const form = document.getElementById("form4");
    const email = document.getElementById("email4");

    form?.addEventListener("submit", formSubmit);

    function formSubmit(e: Event) {
      e.preventDefault();

      //@ts-ignore
      const emailValue = email.value.trim();

      if (emailValue === "") {
        setError(email, "Email can't be blank");
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
      } else {
        setSuccess(email);
      }
    }

    function setError(input: any, message: string) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".input-msg4");
      formControl.className = "form-control error";
      errorMsg.innerText = message;
    }

    function setSuccess(input: any) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email: string) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Styles>
      {/* Footer Two */}
      <footer
        className="footer2"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})`,
        }}
      >
        <Container>
          <Row>
            <Col md="3">
              <div className="footer-logo-info">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <div
                    style={{
                      // backgroundColor: "#bdbdbd",
                      marginTop: 15,
                    }}
                  >
                    <img
                      style={{
                        backgroundColor: "white",
                        paddingInline: 5,
                        paddingBottom: 5,
                      }}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Gradwalklogo.png"
                      }
                      alt=""
                    />
                  </div>
                </Link>
                <ul className="list-unstyled">
                  <li style={{ display: "flex", alignItems: "flex-start" }}>
                    <i className="las la-map-marker"></i>108, Topaz Plaza,
                    Panjagutta, Hyderabad, Telangana, 500082
                  </li>
                  <li>
                    <i className="las la-envelope"></i>
                    help@gradwalk.in
                  </li>
                  <li>
                    <i className="las la-phone"></i>+91 9052378789
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5>Study Destinations</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/study-in-united-states"}
                    >
                      <i className="las la-angle-right"></i>United State
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-canada"}>
                      <i className="las la-angle-right"></i>Canada
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-uk"}>
                      <i className="las la-angle-right"></i>United Kingdom
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-ireland"}>
                      <i className="las la-angle-right"></i>Ireland
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-australia"}>
                      <i className="las la-angle-right"></i>Australia
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-switzerland"}>
                      <i className="las la-angle-right"></i>Switzerland
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-uae"}>
                      <i className="las la-angle-right"></i>United Arab Emirates
                    </Link>
                  </li>{" "}
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/study-in-singapore"}>
                      <i className="las la-angle-right"></i>Singapore
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5>Services for Students</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Councelling"}>
                      <i className="las la-angle-right"></i>Counselling
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Preparation"}>
                      <i className="las la-angle-right"></i>Test Preparation
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Selection"}>
                      <i className="las la-angle-right"></i>Course, Country &
                      Unversity Selections
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/application"}>
                      <i className="las la-angle-right"></i>Application &
                      Admission
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Scholarship"}>
                      <i className="las la-angle-right"></i>Scholarships
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Internship"}>
                      <i className="las la-angle-right"></i>Internship
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/education-Loan"}>
                      <i className="las la-angle-right"></i>Education Loan
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Visa-Process"}>
                      <i className="las la-angle-right"></i>Visa Processing
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Alied-Services"}>
                      <i className="las la-angle-right"></i>Allied Services
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5>About GradWalk</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>
                      <i className="las la-angle-right"></i>About Us
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Career"}>
                      <i className="las la-angle-right"></i>Careers{" "}
                      <Button>We are Hiring!</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/events"}>
                      <i className="las la-angle-right"></i>Events & News
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      <i className="las la-angle-right"></i>Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/courses"}>
                      <i className="las la-angle-right"></i>Search Universities
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/Nexstep-Counselor"}>
                      <i className="las la-angle-right"></i>Become GradWalk
                      Counselor
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/online-Counselling"}>
                      <i className="las la-angle-right"></i>Book online
                      Counselling
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md="12">
              <div className="copytext-area text-center">
                <p>
                  Copyright &copy; 2024 | Designed With{" "}
                  <i className="las la-heart"></i> by{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    // target="_blank"
                    rel="noopener noreferrer"
                  >
                    GradWalk
                  </a>
                </p>
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.facebook.com/nxtstepoverseaseducation"
                      }
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL + "https://x.com/twitt_login"
                      }
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li> */}
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.linkedin.com/company/nxts-step-overseas-education/"
                      }
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href={
                        process.env.PUBLIC_URL +
                        "https://www.instagram.com/nxtstepoverseaseducation/"
                      }
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a target="_blank" href="https://wa.me/90523 78789">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default Footer;
