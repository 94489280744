import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import appStore from "../../mobstore/MobStore";
import { api } from "../../API/API";
import LoginParent from "../../pages/LoginParent";
import Toast from "./Toast";

function MobileMenu() {
  const [loading, setLoading] = useState(false);
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  const handleLogout = async () => {
    const token = appStore.loginResponse.accessToken;
    try {
      const response = await api.post("/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response==", response);
      setToastMessage("Logout successful...!");
      SetToastopen(true);
    } catch (error) {
      console.log("error", error);
    }
    appStore.logout();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      SetToastopen(false);
    }, 1000);
  };

  const { loginResponse } = appStore;
  const isLoggedIn = loginResponse.success;
  const userName = isLoggedIn ? loginResponse.user[0]?.userName : "";

  return (
    <Styles>
      {loading && <LoginParent />}
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-topbar d-flex justify-content-between">
                <div className="topbar-item">
                  <p>
                    <i className="las la-phone"></i>+91 9052378789
                  </p>
                </div>
                <div className="topbar-item">
                  <ul className="list-unstyled list-inline">
                    {isLoggedIn ? (
                      <li className="list-inline-item">
                        <Link
                          to={process.env.PUBLIC_URL + "/"}
                          onClick={handleLogout}
                        >
                          Log out
                        </Link>
                      </li>
                    ) : (
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/login"}>
                          Log In
                        </Link>
                      </li>
                    )}
                    <li className="list-inline-item">/</li>
                    {isLoggedIn ? (
                      <>
                        <li className="list-inline-item">
                          <Link
                            to={process.env.PUBLIC_URL + "/user-applications"}
                          >
                            {userName}
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to={process.env.PUBLIC_URL + "/user-applications"}
                          >
                            My Applications
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/registration"}>
                          Register
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        className="desktop-logo"
                        src={`${process.env.PUBLIC_URL} /assets/images/GradWalkLogo.png`}
                        alt="Desktop Logo"
                      />
                      <img
                        className="mobile-logo"
                        src={`${process.env.PUBLIC_URL}/assets/images/GW-Icon.png`}
                        alt="Mobile Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="mb-search-box">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search Here"
                    />
                    <button type="submit">
                      <i className="las la-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>Menu</h5>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <p>
                Study In <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-united-states"}>
                    United States
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-canada"}>
                    CANADA
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-uk"}>
                    United Kingdom
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-ireland"}>
                    Ireland
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-australia"}>
                    Australia
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-switzerland"}>
                    Switzerland
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-uae"}>
                    United Arab Emirates
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/study-in-singapore"}>
                    Singapore
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <p>
                SERVICES <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Councelling"}>
                    Councelling
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Preparation"}>
                    Test Preparation
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Selection"}>
                    Course, Country & Unversity Selection
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/application"}>
                    Applications & Admissions
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Scholarship"}>
                    Scholarships
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Internship"}>
                    Internship
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/education-Loan"}>
                    Education Loan
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Visa-Process"}>
                    Visa Processing
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Alied-Services"}>
                    Allied Services
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <p>
                Courses <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/courses"}>Courses</Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/course-list"}>
                    Sciences
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-details"}>
                    Arts
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-details"}>
                    Business
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-details"}>
                    Law
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-details"}>
                    Medicine
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/course-details"}>
                    English for Academic Studies
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="mb-menu-item">
            <button className="mb-menu-button">
              <p>
                Instructor <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/instructors"}>
                    Instructors
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <p>
                Event <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/events"}>
                    Upcoming Events
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <p>
                Stories <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>Stories</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button">
              <p>
                Company <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/News-Press"}>
                    News & Press
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/Career"}>
                    Careers (We are Hiring)
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={"success"}
      />
    </Styles>
  );
}

export default MobileMenu;
