import React from "react";
import Datas from "../StudyIn/data/sidebar.json";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";

import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
function SideBarPage() {
  const history = useHistory();
  const handleDestinationClick = (destination: any) => {
    switch (destination) {
      case "Study In USA":
        history.push("/study-in-united-states");
        break;
      case "Study In UK":
        history.push("/study-in-uk");
        break;
      case "Study In Canada":
        history.push("/study-in-canada");
        break;
      case "Study In Australia":
        history.push("/study-in-australia");
        break;
      case "Study In Ireland":
        history.push("/study-in-ireland");
        break;
      case "Study In UAE":
        history.push("/study-in-uae");
        break;
      case "Study In Singapore":
        history.push("/study-in-singapore");
        break;
      case "Study In Switzerland":
        history.push("/study-in-switzerland");
        break;
    }
  };

  return (
    <div className="destinations-container">
      {/* Sidebar content */}
      <div className="destinations">
        <h4 className="destinations-title">Study Destinations To Fly!</h4>
        <div className="destinations-list">
          {Datas.applications[0].Destinations.map((destination, index) => (
            <div key={index} className="destination-item">
              <a
                href=""
                onClick={() => handleDestinationClick(destination.location)}
              >
                <img
                  className="destination-image"
                  src={destination.image}
                  alt={`Flag of ${destination.location}`}
                />
              </a>
              <button
                className="destination-location"
                onClick={() => handleDestinationClick(destination.location)}
              >
                {destination.location.toUpperCase()}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="related-links">
        <h3 className="related-links-title">Related Links</h3>
        <div className="related-links-container">
          {Datas.applications[0].RelatedLinks.map((link, index) => (
            <div key={index} className="related-link-item">
              <Button className="related-link-button">
                {link.toUpperCase()}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className="form-container">
        <h3 className="form-title">The Destination To Fly!</h3>
        <form>
          <input type="text" placeholder="Full Name *" required />
          <input type="tel" placeholder="Mobile Number *" required />
          <input type="email" placeholder="Email Address *" required />
          <select required>
            <option value="">Looking For*</option>
            <option value="general">Study in UK</option>
            <option value="support">Study in USA</option>
            <option value="feedback">Study in Canada</option>
          </select>
          <textarea
            rows={6}
            placeholder="Enter Your Message *"
            required
          ></textarea>
          <button className="Destination-button" type="submit">
            Send Message
          </button>
        </form>
      </div>
      <div className="social-media">
        <span className="follow-label">Follow Us By</span>
        <div className="social-icons">
          <a
            className="facebook"
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={24} />
          </a>
          <a
            className="instagram"
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={24} />
          </a>
          <a
            className="whatsapp"
            href="https://wa.me/YOUR_WHATSAPP_NUMBER"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={24} />
          </a>
          <a
            className="linkedin"
            href="https://www.linkedin.com/in/YOUR_LINKEDIN_PROFILE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn size={24} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SideBarPage;
