import React from "react";
import TextField from "@mui/material/TextField";
import { FieldProps } from "formik";
import { Themecolors } from "./Colors";

type TextInputProps = FieldProps & {
  label: string;
};

const TextInput: React.FC<TextInputProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, e.target.value);
  };
  return (
    <TextField
      {...field}
      label={label}
      name={field.name}
      variant="filled"
      value={field.value ?? ""}
      error={touched[field.name] && Boolean(errors[field.name])}
      onChange={handleInputChange}
      fullWidth
      InputLabelProps={{
        sx: {
          color: Themecolors.InputText_Color1,
          "&.Mui-focused": {
            color: Themecolors.InputText_Color1,
          },
        },
      }}
      sx={{
        "& .MuiFilledInput-root.Mui-focused:after": {
          borderBottom: `2px solid ${Themecolors.Inpute_Border2}`,
        },
      }}
      InputProps={{
        sx: {
          color: Themecolors.InputText_Color1,
          "& .MuiInputBase-input": {
            color: Themecolors.InputText_Color1,
          },
        },
      }}
    />
  );
};

export default TextInput;
