import { Box, Link } from "@mui/material";
import React, { useState } from "react";
import { Button, Collapse, Container, Row } from "react-bootstrap";
import Datas from "../StudyIn/data/irl.json";
import { UsaStyle } from "./UsaStyles";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import AboutUs from "../../About";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import SideBarPage from "./SideBarPage";
import PopupForm from "./PopupForm";

function UaePage() {
  const [open, setOpen] = useState(true);
  const [activeButton, setActiveButton] = useState("overview");
  const [formOpen, setFormOpen] = useState(false);

  const handleEnrollClick = () => {
    setFormOpen(true);
  };
  const handleFormSubmit = () => {
    console.log("from submitted...!");
  };

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  return (
    <UsaStyle>
      <div className="main-wrapper blog-details-page">
        <BreadcrumbBox title="Ireland" />
        <section className="blog-details-area">
          <Container className="main-container">
            <Row>
              <div className="parent">
                {/* Removed Col wrapper for flex behavior */}
                <div className="flex-container">
                  <div className="main-content">
                    <div>
                      {Datas.applications.map((data, index) => (
                        <div key={index}>
                          <div key={data.location} className="application-box">
                            <div className="header">
                              <h1>{data.maintitle}</h1>
                            </div>
                            <div>
                              <img
                                src={data.flag}
                                alt=""
                                className="flag-image img-fluid"
                              />
                            </div>
                            <div className="info-box" key={index}>
                              <div className="flag-country">
                                <img
                                  src={data.usacountry[0].countryflgs}
                                  alt="Country Flag"
                                  className="country-flag img-fluid"
                                />
                              </div>
                              <div className="info-item separator">
                                <b>
                                  {data.usacountry[0].country.capital.label}
                                </b>
                                <p>
                                  {data.usacountry[0].country.capital.value}
                                </p>
                              </div>
                              <div className="info-item separator">
                                <b>
                                  {data.usacountry[0].country.intakes.label}
                                </b>
                                <p>
                                  {data.usacountry[0].country.intakes.value}
                                </p>
                              </div>
                              <div className="info-item separator">
                                <b>
                                  {
                                    data.usacountry[0].country.scholarships
                                      .label
                                  }
                                </b>
                                <p>
                                  {
                                    data.usacountry[0].country.scholarships
                                      .value
                                  }
                                </p>
                              </div>
                              <div className="info-item">
                                <button
                                  className="enroll-button"
                                  onClick={handleEnrollClick}
                                >
                                  <i className="fas fa-chart-bar"></i> ENROLL
                                </button>
                              </div>
                            </div>
                            <div className="overview-button">
                              {data.buttons.map((button) => (
                                <button
                                  key={button.id}
                                  className={`styled-button ${
                                    activeButton === button.id
                                      ? "active-button"
                                      : ""
                                  }`}
                                  onClick={() => handleButtonClick(button.id)}
                                >
                                  {button.label}
                                </button>
                              ))}
                            </div>
                            <Box>
                              {activeButton === "overview" && (
                                <div className="button-content">
                                  <p>{data.overviewContent.introduction}</p>{" "}
                                  <p>{data.overviewContent.introduction1}</p>
                                  <div className="main-dropdown">
                                    <div
                                      className="dropdown-header"
                                      onClick={() => setOpen(!open)}
                                      aria-expanded={open}
                                    >
                                      <a>
                                        {data.overviewContent.dropdown.header}
                                      </a>
                                      {!open ? (
                                        <span style={{ marginLeft: "8px" }}>
                                          <i className="fas fa-chevron-right"></i>
                                        </span>
                                      ) : (
                                        <span style={{ marginLeft: "8px" }}>
                                          <i className="fas fa-chevron-down"></i>
                                        </span>
                                      )}
                                    </div>
                                    <Collapse in={open}>
                                      <div className="collapse-content">
                                        {data.overviewContent.dropdown.details.map(
                                          (item) => (
                                            <div
                                              className="university-row"
                                              key={item.id}
                                            >
                                              <div
                                                className="university-row"
                                                key={item.id}
                                              >
                                                <div className="university-content">
                                                  <span>
                                                    <span className="area-label">
                                                      {item.label}
                                                    </span>
                                                    {item.value}
                                                  </span>
                                                  <span className="additional">
                                                    {item.additional}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Collapse>
                                  </div>
                                  <div className="Education">
                                    <h4>
                                      {data.overviewContent.education.title}
                                    </h4>
                                    <p>
                                      {data.overviewContent.education.about}
                                    </p>{" "}
                                    <p>
                                      {data.overviewContent.education.about1}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Box>
                            <Box>
                              {activeButton === "eligibility" && (
                                <div className="button-eligibility">
                                  <h4>
                                    {" "}
                                    {data.eligibilityContent.introduction.title}
                                  </h4>
                                  <p className="text">
                                    {data.eligibilityContent.introduction.about}
                                  </p>
                                  <div className="point-wise">
                                    {data.eligibilityContent.introduction.points.map(
                                      (point, index) => (
                                        <div key={index} className="point-item">
                                          <i className="fas fa-check-double"></i>
                                          <span>{point}</span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <p>
                                    {data.eligibilityContent.introduction.note}
                                  </p>
                                </div>
                              )}
                            </Box>
                            <Box>
                              {activeButton === "university" && (
                                <div className="university">
                                  <h4>{data.universityContent.title}</h4>
                                  <p>{data.universityContent.about}</p>
                                  <p>{data.universityContent.about1}</p>
                                  <div>
                                    <h4>{data.universityContent.title2}</h4>
                                    <p>
                                      {data.universityContent.introduction1}
                                    </p>
                                    <p>
                                      {data.universityContent.introduction2}
                                    </p>
                                    <p>
                                      {data.universityContent.introduction3}
                                    </p>
                                  </div>
                                  <div className="point-wise">
                                    <div>
                                      <h4>{data.universityContent.title3}</h4>
                                      <p>{data.universityContent.about3}</p>
                                    </div>
                                    {data.universityContent.points.map(
                                      (point: string, pointIndex: number) => (
                                        <div
                                          key={pointIndex}
                                          className="point-item"
                                        >
                                          <i className="fas fa-check-double"></i>
                                          <span>{point}</span>
                                        </div>
                                      )
                                    )}
                                    <p>{data.universityContent.note}</p>
                                  </div>
                                </div>
                              )}
                            </Box>
                            <Box>
                              {activeButton === "work" && (
                                <div className="work-study">
                                  <h4>{data.workstudy.title1}</h4>
                                  <p>{data.workstudy.about1}</p>
                                  <div className="point-wise">
                                    {data.workstudy.ponits.map(
                                      (point, index) => (
                                        <div key={index} className="point-item">
                                          <i className="fas fa-check-double"></i>
                                          <span>{point}</span>
                                        </div>
                                      )
                                    )}
                                    <div>
                                      <p>{data.workstudy.note}</p>
                                    </div>
                                    <div>
                                      <h4>{data.workstudy.title1}</h4>
                                      <p>{data.workstudy.about2}</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Box>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <SideBarPage />
                </div>
              </div>
            </Row>
          </Container>
        </section>
        <AboutUs />
        <PopupForm
          open={formOpen}
          onClose={() => setFormOpen(false)}
          onSubmit={handleFormSubmit}
        />
      </div>
    </UsaStyle>
  );
}

export default UaePage;
