import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../components/styles/loginPage/account";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import Toast from "../components/common/Toast";
import LoginParent from "./LoginParent";
import { api } from "../API/API";
import { Box, Card, Typography } from "@mui/material";

function ForgotPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );
  const [showEmailMessage, SetShowEmailMessage] = useState(false);
  const handleformsubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = document.getElementById("forgot_email") as HTMLInputElement;

    const userValue = user?.value.trim();

    if (!userValue) {
      setToastMessage("Email cannot be empty");
      setToastSeverity("error");
      SetToastopen(true);
      return;
    }

    if (userValue) {
      setLoading(true);
      try {
        const response = await api.post("forgot-password-email", {
          body: {
            userEmail: userValue,
          },
        });
        if (response && response.success) {
          setLoading(false);
          SetShowEmailMessage(true);
        }
      } catch (error) {
        console.error("Error sending password reset email:", error);
      }
    }
  };

  return (
    <Styles>
      {loading && <LoginParent />}
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Forgot Password" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                {!showEmailMessage ? (
                  <div className="login-box">
                    <div className="login-title text-center">
                      <h3>Forgot Password</h3>
                    </div>
                    <form
                      id="form_forgot_password"
                      className="form"
                      onSubmit={handleformsubmit}
                    >
                      <p className="form-control">
                        <label htmlFor="forgot_email">User Email</label>
                        <input
                          type="email"
                          placeholder="UserEmail"
                          id="forgot_email"
                        />
                        <span className="login_input-msg"></span>
                      </p>
                      <button type="submit" disabled={loading}>
                        Forgot Password
                      </button>
                      <button
                        type="button"
                        onClick={() => history.push("/login")}
                      >
                        Go Back
                      </button>
                    </form>
                  </div>
                ) : (
                  <div className="login-box">
                    <form
                      id="form_forgot_password"
                      className="form"
                      onSubmit={handleformsubmit}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.2em",
                          color: "green",
                          paddingY: 3,
                          paddingX: 1,
                        }}
                      >
                        We have sent a password reset email. Please check your
                        inbox.
                      </Typography>
                      <button
                        type="button"
                        onClick={() => history.push("/login")}
                      >
                        Go Back
                      </button>
                    </form>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Toast
        open={toastopen}
        onClose={() => SetToastopen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </Styles>
  );
}

export default ForgotPassword;
