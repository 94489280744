import React, { useState } from "react";
import { FieldProps } from "formik";
import { Button, Box, Typography, Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Themecolors } from "./Colors";

type CustomUploadFileProps = FieldProps & {
  label: string;
};

const CustomUploadFile: React.FC<CustomUploadFileProps> = ({
  field,
  form: { setFieldValue },
}) => {
  const [filesPresent, setFilesPresent] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFieldValue(field.name, Array.from(files));
      setFilesPresent(true);
    }
  };

  const handleFileClick = (file: any) => {
    window.open(URL.createObjectURL(file), "_blank");
  };

  const [inputId] = useState(uuidv4());

  return (
    <div>
      <input
        accept="image/*,.pdf"
        style={{ display: "none" }}
        id={inputId}
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor={inputId}>
        <Button
          variant="outlined"
          component="span"
          sx={{
            "&:hover": {
              borderColor: Themecolors.UP_Button_Border,
              color: Themecolors.UP_Button1,
            },
          }}
        >
          Upload
        </Button>
      </label>
      {filesPresent && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {field.value?.map((file: File, index: number) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    border: "1px solid #000",
                    padding: 1,
                    minWidth: 100,
                    minHeight: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleFileClick(file)}
                >
                  <Button sx={{ padding: 0 }}>
                    <Typography variant="body2" color={"black"}>
                      {file.name}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default CustomUploadFile;
