import React from "react";
import HeroSlider from "../components/HeroSlider";
import CampusTour from "../components/CampusTour";
import TestimonialSlider from "../components/TestimonialSlider";
import AboutUs from "../components/About";
import CourseFilter from "../components/CourseFilter";

class Home extends React.Component {
  render() {
    return (
      <div>
        <HeroSlider />
        <AboutUs />
        <CourseFilter />
        <TestimonialSlider />
        <CampusTour />
      </div>
    );
  }
}

export default Home;
