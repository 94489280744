import { Box, Button, styled } from "@mui/material";
import { BreadcrumbBox } from "../Breadcrumb";
import TestimonialSlider from "../../TestimonialSlider";
import { useHistory } from "react-router-dom";
import { ErrorStyles } from "./ErrorStyles";
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: "column",
  justifyContent: "center",
}));

const IMG = styled("img")(() => ({
  width: "100%",
  marginBottom: "32px",
}));

const NotFoundRoot = styled(FlexBox)(() => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh !important",
}));

const NotFound404 = () => {
  const history = useHistory();
  return (
    <>
      <ErrorStyles>
        {/* Main Wrapper */}
        <BreadcrumbBox title="Not Found 404" />
        <div className="main-wrapper about-page">
          {/* Breadcroumb */}
          <Box sx={{ marginX: 10 }}>
            <div style={{ marginBottom: "12em" }}>
              <NotFoundRoot>
                <JustifyBox>
                  <IMG src="/assets/images/illustrations/404.svg" alt="" />

                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </Button>
                </JustifyBox>
              </NotFoundRoot>
            </div>
            <TestimonialSlider />
          </Box>
        </div>
      </ErrorStyles>
    </>
  );
};

export default NotFound404;
