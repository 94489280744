import React, { Component } from "react";
import { Styles } from "../styles/coursePrice";
import { Query } from "@testing-library/react";

type PriceRange = "price1" | "price2" | "price3";

interface CoursePriceProps {
  OnChangePrice?: (selectedPriceRanges: PriceRange[]) => void;
  counts: {
    all: number;
    belowTenThousand: number;
    betweenTenAndTwentyThousand: number;
    aboveTwentyThousand: number;
  };
}

class CoursePrice extends Component<CoursePriceProps> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxes =
      document.querySelectorAll<HTMLInputElement>(".check-box");
    const selectedPriceRanges: PriceRange[] = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedPriceRanges.push(checkbox.id as PriceRange);
      }
    });

    if (this.props.OnChangePrice) {
      this.props.OnChangePrice(selectedPriceRanges);
    }
  };

  render() {
    const { counts } = this.props;
    return (
      <Styles>
        {/* Course Price */}
        <div className="course-price">
          <h5>Course Price</h5>
          <ul className="price-item list-unstyled">
            <li className="check-btn">
              <label htmlFor="price1">
                <input
                  type="checkbox"
                  id="price1"
                  className="check-box"
                  onChange={this.handleChange}
                />
                All<span>({counts.all})</span>
              </label>
            </li>
            <li className="check-btn">
              <label htmlFor="price2">
                <input
                  type="checkbox"
                  id="price2"
                  className="check-box"
                  onChange={this.handleChange}
                />
                Below - 10,000<span>({counts.belowTenThousand})</span>
              </label>
            </li>
            <li className="check-btn">
              <label htmlFor="price3">
                <input
                  type="checkbox"
                  id="price3"
                  className="check-box"
                  onChange={this.handleChange}
                />
                10,000 - 20,000
                <span>({counts.betweenTenAndTwentyThousand})</span>
              </label>
            </li>
            {/* <li className="check-btn">
              <label htmlFor="price4">
                <input type="checkbox" id="price4" className="check-box" />
                Scholarship<span>(35)</span>
              </label>
            </li> */}
          </ul>
        </div>
      </Styles>
    );
  }
}

export default CoursePrice;
