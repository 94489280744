export const colors = {
  bg1: "rgb(22 55 90)", // blue for sticky-manu, footer
  bg2: "#F6F9FF", //white back-groung white entire page
  gr_bg: "#ffab40", // yellow for header
  gr_bg2:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", //yellow hover for  button
  gr_bg3: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover for button
  copy_bg: "#122340",
  blue: "#2c97ea",
  green: "#ffab40", //yellow for icons
  green2: "#ffab40", // yellow for button

  // HeaderBox

  H_bg1: "#ffab40", // Orange color for headerBox
  H_bg2: "rgb(22 55 90)", // blue backgroundColor headerBox
  H_hv: "linear-gradient(360deg,  white 0%, #eeeeee 100%)", // white hover headerbox
  H_hv2: "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  H_hv3: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  H_hv1: "", // blue border line
  H_text1: "rgb(22 55 90)", // blue
  H_text2: "#ffab40", // orange
  H_text3: "#F6F9FF", // white
  H_text4: "#616161", // ash
  H_text5: "#e0e0e0", // lite white
  H_text6: "#969696", // lite ash
  H_text7: "#555555", // black lite
  H_text_black1: "#182B49", // black text color
  H_border1: "#3e3e3e", // black
  H_border2: "#eeeeee", // lite ash
  H_icon1: "#ffab40", // Orange color for icon
  H_icon2: "rgb(22 55 90)", // blue backgroundColor icon
  H_button: "#ffab40", // yellow for button
  H_button_hv1:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", //yellow hover for  button

  //Events
  E_bg1: "#ffab40", // Orange color
  E_bg2: "rgb(22 55 90)", // blue
  E_icon1: "#ffab40", // Orange color for icon
  E_icon2: "rgb(22 55 90)", // blue backgroundColor icon
  E_text1: "#182B49", // black text color
  E_text2: "#969696", // lite ash
  E_text3: "#666666", //
  E_text4: "#444444", // black
  E_text5: "#ffab40", // orange
  E_border1: "#3e3e3e", // black
  E_border2: "#eeeeee", // lite ash
  E_border3: "#dddddd", // lite ash
  E_Button_text1: "#3e3e3e", // black
  E_Button_border1: "#ffab40", //  orange,
  E_Button_border2: "#dddddd", // lite ash
  E_Button_hv1:
    "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover

  // Buttons
  Button1: "#ffab40", //  orange
  Button2: "rgb(22 55 90)", // blue
  button_bg1: "#ffab40", // orange background color
  Button_bg2: "rgb(22 55 90)", // blue background color
  Button_bg3: "rgb(240 240 240)", // ASH background color
  Button_bg4: " #f5f5f5 ", // ash color
  Button_border1: "#ffab40", //  orange,
  Button_border2: "rgb(22 55 90)", // blue
  Button_border3: "#969696", // lite ash
  Button_border4: "#eeeeee", // more lite ash
  B_hv1: "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  B_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover
  B_hv3: "linear-gradient(270deg, #f5f5f5 0%, rgb(220 220 220) 100%)", // ash hover
  B_text1: "#3e3e3e", // black
  B_text2: "#757575", // ash
  B_text3: "white",

  // Sidebar
  S_menu_icon: "#eeeeee",
  S_bg1: "#ffab40", // Orange color for background
  S_bg2: "rgb(22 55 90)", // blue backgroundColor header
  S_bg3: "#fafafa", // white
  S_Icon1: "#ffab40", //  orange
  S_Icon2: "rgb(22 55 90)", // blue
  S_Icon_bg1: "#ffab40", // orange background color
  S_Icon_bg2: "rgb(22 55 90)", // blue background color
  S_Icon_bg3: "#fafafa",
  S_icon_close: "rgb(22 55 90)", // blue
  S_icon_border: "#eeeeee", // blue
  S_text1: "#fafafa", // white
  S_Header_text: "#ffab40", // orange
  S_text3: "#757575", // ash
  S_border1: "#ffab40", // orange
  S_hv1: "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)", // ornge hover
  S_hv2: "linear-gradient(90deg,rgb(73 106 142) 0%, rgb(27 37 80) 100%)", // blue hover

  //Text field & numeric Fields & TextArea

  InputText_Color1: "rgb(22 55 90)", // blue
  InputText_Color2: "black", // black
  InputText_Color3: "#ffab40", // orange
  Inpute_Border1: "rgb(22 55 90)", // blue
  Inpute_Border2: "#ffab40", // orange
  Inpute_hv1: "#ffab40", // hover orange
  Inpute_hv2: "rgb(22 55 90)", // hover  blue
  // radio button
  RB_Selected1: "rgb(22 55 90)", // black
  RB_Selected2: "#ffab40", // orange
  // Upload Button
  UP_Button1: "rgb(22 55 90)", // blue
  UP_Button2: "#ffab40", // orange
  UP_Button_Border: "#ffab40", //orange hover

  // remaning
  red: "#ff6f6f",
  purple: "#84479c",
  yellow: "#fbab19",
  black1: "#182B49", // text color
  black2: "#444444", // black
  text1: "#555555",
  text2: "#666666", //
  text3: "#969696", // lite ash
  text4: "#e0e0e0", // white lite
  text5: "#cccccc",
  border1: "#eeeeee",
  border2: "#3e3e3e", // black
  border3: "#dddddd", // lite ash
  footer1: "#1a1b25",
  footer2: "#16171f",
  ftext: "#8D8E92",
};

export const fonts = {
  roboto: "'Roboto', sans-serif",
  poppins: "'Poppins', sans-serif",
};
