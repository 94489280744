import React, { Component } from "react";
import Datas from "../../data/event/events.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/event.js";

class Events extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-page" style={{ marginTop: "50px" }}>
          <BreadcrumbBox title="Events" />
          {/* Events Area */}
          <section className="event-page-area">
            <Container>
              <Row>
                <Col lg="12" md="12">
                  {Datas.map((data, i) => (
                    <div className="event-box" key={i}>
                      <Row>
                        <Col xl="3" lg="4">
                          <div className="event-img">
                            <Link to={process.env.PUBLIC_URL + data.eventLink}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.eventImg}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </Col>
                        <Col xl="9" lg="8" md="12">
                          <div className="event-content">
                            <div className="content-box">
                              <Row>
                                <Col md="9">
                                  <div className="event-title">
                                    <h6>
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          data.eventLink
                                        }
                                      >
                                        {data.eventTitle}
                                      </Link>
                                      (Spot assessment)
                                    </h6>
                                  </div>
                                  <div className="event-time-location">
                                    <ul className="list-unstyled list-inline">
                                      <li className="list-inline-item">
                                        <i className="fas fa-university"></i>
                                        {data.University}
                                      </li>
                                      <ul>
                                        <li className="list-inline-item">
                                          <i className="las la-book"></i>
                                          {data.Apply}
                                        </li>
                                      </ul>
                                      <li className="list-inline-item">
                                        <i className="las la-clock"></i>
                                        {data.eventTime}
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="las la-map-marker"></i>
                                        {data.eventLocation}
                                      </li>{" "}
                                      <li className="list-inline-item">
                                        <i className="fa fa-file"></i>
                                        {data.eventdesc}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="copytext-area text-center">
                                    <ul className="social list-unstyled list-inline">
                                      <li className="list-inline-item">
                                        <a
                                          target="_blank"
                                          href={
                                            process.env.PUBLIC_URL +
                                            "https://www.facebook.com/nxtstepoverseaseducation"
                                          }
                                        >
                                          <i className="fab fa-facebook-f"></i>
                                        </a>
                                      </li>

                                      <li className="list-inline-item">
                                        <a
                                          target="_blank"
                                          href={
                                            process.env.PUBLIC_URL +
                                            "https://www.linkedin.com/company/nxts-step-overseas-education/"
                                          }
                                        >
                                          <i className="fab fa-linkedin-in"></i>
                                        </a>
                                      </li>
                                      <li className="list-inline-item">
                                        <a
                                          target="_blank"
                                          href={
                                            process.env.PUBLIC_URL +
                                            "https://www.instagram.com/nxtstepoverseaseducation/"
                                          }
                                        >
                                          <i className="fab fa-instagram"></i>
                                        </a>
                                      </li>
                                      <li className="list-inline-item">
                                        <a
                                          target="_blank"
                                          href="https://wa.me/90523 78789"
                                        >
                                          <i className="fab fa-whatsapp"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>
                                <Col md="3" className="text-center">
                                  <div className="event-date">
                                    <p>{data.eventDate}</p>
                                  </div>
                                  <div className="join-btn">
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL + data.eventLink
                                      }
                                    >
                                      Join Now
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Styles>
    );
  }
}

export default Events;
