// SingleSelectionComponent.tsx
import React, { useEffect, useState } from "react";

interface Option {
  [key: string]: string;
}

interface SingleSelectionComponentProps {
  options: { [key: string]: string };
  onChange: (selectedOption: string) => void;
}

const SingleSelectionComponent: React.FC<SingleSelectionComponentProps> = ({
  options,
  onChange,
}) => {
  const [formattedOptions, setFormattedOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  useEffect(() => {
    const formatted = Object.keys(options).map((key) => ({
      id: key,
      label: options[key],
    }));
    setFormattedOptions(formatted);
    setSelectedOption(formatted[0].id); // Set the first option as the default selected option
  }, [onchange, options]);

  const handleClick = (optionId: string) => {
    setSelectedOption(optionId);
    onChange(optionId);
  };

  return (
    <div
      className="selection-container"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {formattedOptions.map((option) => (
        <button
          key={option.id}
          className={`selection-button ${
            selectedOption === option.id ? "selected" : ""
          }`}
          onClick={() => {
            handleClick(option.id);
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default SingleSelectionComponent;
