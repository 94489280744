import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .tab-section {
    background: ${colors.bg2};
    padding: 70px 0 65px;

    .nav {
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid ${colors.border1};
      overflow: hidden;

      .nav-item {
        a.nav-link {
          font-size: 15px;
          color: ${colors.black1};
          border-bottom: 1px solid ${colors.border1};
          padding: 18px 25px;

          i {
            color: ${colors.green};
          }
        }

        a.nav-link.active {
          background: ${colors.gr_bg};
          color: #ffffff;

          i {
            color: #ffffff;
          }
        }

        &:last-child {
          a.nav-link {
            border-bottom: none;
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .tab-content {
      .tab-pane {
        h4.tab-title {
          color: ${colors.black1};
          font-weight: 600;
          margin-bottom: 25px;

          @media (max-width: 575px) {
            margin-bottom: 15px;
            font-size: 20px;
          }
        }

        h6.title {
          color: ${colors.black2};
          font-weight: 600;

          @media (max-width: 575px) {
            margin-bottom: 15px;
            font-size: 20px;
          }
        }

        p.tab-desc {
          font-size: 15px;
          color: ${colors.text2};
          line-height: 30px;
          margin-bottom: 25px;

          @media (max-width: 575px) {
            font-size: 14px;
          }
        }

        ul.check-list {
          li {
            font-size: 15px;
            color: ${colors.text3};
            margin-bottom: 20px;
            line-height: 25px;

            i {
              float: left;
              color: ${colors.green};
              border: 1px solid ${colors.border3};
              width: 35px;
              height: 35px;
              border-radius: 50%;
              text-align: center;
              padding-top: 9px;
              margin-top: 7px;
              margin-right: 15px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 35px 0 30px;
    }
  }

  .instructor-area {
    margin-top: 20px;

    .instructor-item {
      position: relative;

      img {
        width: 100%; /* Ensures the image fits the container */
        height: auto; /* Maintains the aspect ratio */
        display: block; /* Removes extra space below the image */
        border-radius: 5px; /* Optional: Add border radius if desired */
      }

      .img-content {
        position: absolute;
        left: 10px;
        width: calc(100% - 20px); /* Ensures content fits within the image */
        background: #ffffff;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        z-index: 1; /* Ensures content is above the image */
      }

      .list-inline-item {
        margin: 0 5px; /* Space between social icons */
      }

      .list-inline-item a {
        height: 1.7em;
        width: 1.7em;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-decoration: none;
      }

      .list-inline-item a i {
        font-size: 0.9em; /* Adjusts icon size */
      }
    }
  }

  @media (max-width: 767px) {
    .instructor-item {
    }

    .img-content {
      position: static; /* Stacks content below the image on mobile */
      bottom: auto;
      left: auto;
      width: 100%;
      padding: 10px;
      text-align: center;
    }

    .list-inline-item a {
      height: 1.5em;
      width: 1.5em;
    }

    .list-inline-item a i {
      font-size: 0.8em; /* Smaller icon size for mobile */
    }
  }
`;
