import React, { Component, useEffect, useState } from "react";
import { Styles } from "../styles/courseCategory";
import { boolean } from "yup";

interface categoryProps {
  courses: any[];
  onChangeSelectCategory?: (selectedCategories: string[]) => void;
}
const CourseCategory: React.FC<categoryProps> = ({
  courses,
  onChangeSelectCategory,
}) => {
  const concentrationCounts = courses.reduce((acc, course) => {
    const concentration = course.Concentration;
    if (concentration) {
      acc[concentration] = (acc[concentration] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);

  const concentrations = Object.keys(concentrationCounts);

  // const concentrations = Array.from(
  //   new Set(courses.map((course) => course.Concentration).filter(Boolean))
  // );
  // console.log("hari==", concentrations);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    if (onChangeSelectCategory) {
      onChangeSelectCategory(selectedCategories);
    }
  }, [selectedCategories, onChangeSelectCategory]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    setSelectedCategories((prevState) => {
      const newSelection = isChecked
        ? [...prevState, value]
        : prevState.filter((category) => category !== value);

      return newSelection;
    });
  };

  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category">
        <h5>Course Category</h5>
        <ul className="category-item list-unstyled">
          {concentrations.map((concentration, index) => (
            <li className="check-btn" key={index}>
              <label htmlFor={`cat${index}`}>
                <input
                  type="checkbox"
                  id={`cat${index}`}
                  className="check-box"
                  value={concentration}
                  onChange={handleChange}
                />
                {concentration}
                <span>({concentrationCounts[concentration]})</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </Styles>
  );
};

export default CourseCategory;
