import React from "react";
import { LinearProgress } from "@mui/material";

function LoginParent() {
  return (
    <div style={{ width: "100%", position: "fixed", top: 0, zIndex: 9999 }}>
      <LinearProgress />
    </div>
  );
}

export default LoginParent;
