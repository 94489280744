import React, { useEffect, useState, useRef } from "react";

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { Step, Stepper, StepLabel, Grid, Divider } from "@mui/material";
import { Button, Box, Typography, CardContent, Card } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { renderFormFields } from "./FormFields";
import { v4 as uuidv4 } from "uuid";
import { Themecolors } from "./Colors";
import appStore from "../../mobstore/MobStore";
import { api } from "../../API/API";
import Toast from "./Toast";
import SimpleDialog from "./SimpleDialog";

interface User {
  userId?: number;
  userName?: string;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  userImage?: null | string;
  userAddress?: null | string;
  userServerEmail?: null | string;
  userPhoneOne?: null | string;
  userPhoneTwo?: null | string;
  userLastLogin?: null | string;
  userCreated?: null | string;
  userEnabled?: number;
  userLocked?: number;
  userType?: number;
  userPassword?: null | string;
  entities?: string;
  roles?: string;
}
interface IAttribute {
  attributeName: string;
  attributeDescription: string;
  attributeType: number;
  order: string;
}
interface IUploadType {
  uploadName: string;
  fileData: any;
  fileName: string;
  fileSize: number;
  type: string;
}

interface FormValues {
  fieldName: string;
}
interface DocumentFormProps {
  documentTypeId: number[];
  userObj?: User;
  showFormDoc?: Function;
  onCloseMainDialog: () => void;
}
const DocumentForm: React.FC<DocumentFormProps> = ({
  userObj,
  showFormDoc,
  onCloseMainDialog,
  documentTypeId,
}) => {
  const FormRef = useRef<FormikProps<FormValues>>(null);
  const [formData, setFormData] = useState<any>([]);
  const [step, setStep] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname;
  const params = currentPath.split("/");
  const [documentType, setDocumentType] = useState(0);
  const [selections, setSelections] = useState([]);
  const [msgDialog, setMsgDialog] = useState(false);
  const [documentTagType, setDocumentTagType] = useState("1");
  const user: User = userObj ?? appStore.loginResponse.user[0];
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [showFormViewer, setShowFormViewer] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const token = appStore.loginResponse.accessToken;
  const history = useHistory();
  const [toastopen, SetToastopen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<"success" | "error">(
    "success"
  );

  const documentIdMap: Map<string, number[]> = new Map();

  function getUniqueActionNames(actionNames: number[]) {
    actionNames.forEach((id) => {
      const key = `documentTypeId:${id}`;
      if (!documentIdMap.has(key)) {
        documentIdMap.set(key, []);
      }
      documentIdMap.get(key)?.push(id);
    });
  }
  getUniqueActionNames(documentTypeId);

  const ActiveIds = Array.from(documentIdMap.keys()).map((key) => {
    return parseInt(key.split(":")[1]);
  });

  useEffect(() => {
    const selectedDocumentId = documentTypeId ?? params[params.length - 1];
    let canSubmit = false;
    api
      .post(`check-user-submitted-document`, {
        body: {
          userId: user.userId,
          documentTypeId: documentTypeId[0] ?? params[params.length - 1],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data > 0) {
          api
            .post(`check-user-document-permission`, {
              body: {
                userId: user.userId,
                userType: user.userType,
                documentTypeId: selectedDocumentId,
              },
            })
            .then((res) => {
              if (res.data == 0) {
                canSubmit = false;
              } else {
                canSubmit = true;
              }
            });
        } else {
          canSubmit = true;
        }
        if (canSubmit) {
          const typeId = documentTypeId ?? params[params.length - 1];
          api
            .get(`document-type/get-object/${typeId}`, {
              headers: {
                Authorization: `bearer ${token}`,
              },
            })
            .then((data) => {
              if (data.status) {
                setFormData(data.data[0].documentTypeObject.sections);
                setDocumentType(data.data[0].documentTypeObjectId);
                setSelections(data.data[0].documentTypeObject.selections);
                setDocumentTagType(
                  data.data[0].documentTypeObject?.documentTagType ?? "1"
                );
              }
            });
        } else {
          setShowFormViewer(true);
        }
      });

    return () => {
      setStep(0);
      setFormData([]);
      setShowFormViewer(false);
    };
    //setFormData(data.sections);
  }, [params[params.length - 1]]);

  const getValidationFromStr = (str: any) => {
    let validation = Yup.string();
    const validators = str.split(",");

    for (let i = 0; i < validators.length; i++) {
      if (validators[i] === "req") {
        validation = validation.required("Required");
      } else if (validators[i] === "email") {
        validation = validation.email("Invalid email address");
      } else if (validators[i] === "min") {
        const num = parseInt(validators[i + 1]);
        if (!isNaN(num)) {
          validation = validation.min(
            num,
            `Must be at least ${num} characters`
          );
          i++; // skip the next loop since it's a parameter for 'min'
        }
      }
      // Add other validators as needed...
    }
    return validation;
  };

  const generateValidationSchema = (sections: any) => {
    const schema: Record<string, any> = {};
    sections.forEach((section: any) => {
      const validationSchema = section.validationSchema;
      for (const key in validationSchema) {
        schema[key] = getValidationFromStr(validationSchema[key]);
      }
    });

    return Yup.object(schema);
  };
  const validationSchema = generateValidationSchema(formData);
  const initialValues = formData[step]?.initialValues ?? {};
  const formDetails: any[] = formData[step]?.formDetails ?? [];
  const sections = formData.length;

  const handleNext = async () => {
    if (FormRef.current) {
      const formik = FormRef.current;

      const errors = await formik.validateForm();
      if (stepHasErrors(errors, step, formData)) {
        setFormHasErrors(true);
      } else {
        setFormHasErrors(false);
      }
    }
    if (step < sections - 1) setStep(step + 1);
  };

  const onBack = () => {
    if (step > 0) setStep(step - 1);
  };
  const hanldeClose = () => {
    history.push("/dashboard/default");
  };
  const handleCloseButton = () => {
    history.push("");
  };
  const getSelections = (order: any) => {
    const splitOrder = String(order).split("-");
    if (selections) {
      const questionIndex = +splitOrder[0];
      const res = selections.reduce((acc: any, curr: any) => {
        if (curr.questions.includes(questionIndex)) {
          return [...acc, ...curr.options];
        }
        return acc;
      }, []);
      return res;
    } else {
      return [];
    }
  };

  const handleAddNewRow = (sectionIndex: any, groupIndex: any) => {
    // Clone the section
    const newSections = [...formData];
    const repeatedForm = newSections[sectionIndex].formDetails[groupIndex].form;
    const currentIndex = newSections[sectionIndex].formDetails.length - 1;
    // Add the repeated form group
    newSections[sectionIndex].formDetails.push({
      groupName: newSections[sectionIndex].formDetails[groupIndex].groupName,
      repeated: true,
      form: repeatedForm.map((item: any, index: any) => ({
        ...item,
        order: `${item.order}-${currentIndex}`,
      })),
    });

    setFormData(newSections);
  };

  const stepHasErrors = (errors: any, currentStep: any, formData: any) => {
    const currentFormDetails = formData[currentStep]?.formDetails ?? [];
    const hasErrors = currentFormDetails.some((group: any) =>
      group.form.some((item: any) => errors[item.order])
    );
    setFormHasErrors(Object.entries(errors).length > 0);
    return hasErrors;
  };

  const UserType = appStore.loginResponse.user[0].userType;
  if (showFormViewer) {
    return (
      <>
        <SimpleDialog
          show={showFormViewer}
          description="Application has already created for the selected user."
          title="Submitted Successfully"
          buttonText="Close"
          onClose={() => {
            handleCloseButton();
            onCloseMainDialog();
          }}
        />
      </>
    );
  }

  const handleSubmit = () => {
    if (FormRef.current && !formHasErrors) {
      FormRef.current.submitForm();
    } else {
      setShowErrorToast(true);
    }
  };

  const showButton = sections === 1 || step === sections - 1;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f4f6f8",
        width: "100%",
        height: "100%",
      }}
    >
      {/* <Typography variant="h6" gutterBottom>
        Submit Application
      </Typography> */}
      <Card
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          padding={"10px"}
          justifyContent={"space-between"}
          display={"flex"}
          width={"100%"}
          style={{
            backgroundColor: Themecolors.F_bg2,
            color: Themecolors.F_text1,
          }}
        >
          {step >= 1 && (
            <Button
              type="button"
              variant="outlined"
              onClick={onBack}
              sx={{
                borderColor: Themecolors.Button1,
                backgroundColor: Themecolors.Button1,
                color: Themecolors.Button2,
                "&:hover": {
                  backgroundImage: Themecolors.B_hv1,
                  borderColor: Themecolors.Button1,
                },
              }}
            >
              Back
            </Button>
          )}
          <Typography variant="h6" align="left" gutterBottom>
            {formData[step]?.sectionName ?? ""}
          </Typography>
          {step < sections - 1 && (
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={handleNext}
              sx={{
                borderColor: Themecolors.Button1,
                backgroundColor: Themecolors.Button1,
                color: Themecolors.Button2,
                "&:hover": {
                  backgroundImage: Themecolors.B_hv1,
                  borderColor: Themecolors.Button1,
                },
              }}
            >
              Next
            </Button>
          )}
          {showButton && (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              sx={{
                borderColor: Themecolors.Button1,
                backgroundColor: Themecolors.Button1,
                color: Themecolors.Button2,
                "&:hover": {
                  backgroundImage: Themecolors.B_hv1,
                  borderColor: Themecolors.Button1,
                },
              }}
            >
              Submit
            </Button>
          )}
        </Grid>

        <CardContent sx={{ height: "91%", padding: 0 }}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              height: "100%",
            }}
          >
            <Formik
              innerRef={FormRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              key={documentType}
              onSubmit={(values, {}) => {
                const formValues = { ...values };

                const files: IUploadType[] = [];
                const tasks: string[] = [];
                formData.map((section: any) => {
                  section.formDetails.map((f: any) => {
                    f.form.map((res: any) => {
                      if (res.attributeType == "5") {
                        const uploadNames: string[] = [];
                        if (!!formValues[`${res.order}`]) {
                          Array.from(formValues[`${res.order}`]).map(
                            (file: any) => {
                              const inputId = uuidv4();
                              uploadNames.push(inputId);
                              files.push({
                                uploadName: inputId,
                                fileData: file,
                                fileName: file.name,
                                fileSize: file.size,
                                type: file.type,
                              });
                            }
                          );

                          formValues[`${res.order}`] = uploadNames;
                        }
                      } else if (res.attributeType == "10") {
                        tasks.push(formValues[`${res.order}`]);
                      }
                    });
                  });
                });

                for (const activeId of ActiveIds) {
                  const answerObject = {
                    documentTypeId: activeId,
                    userId:
                      userObj?.userId ?? appStore.loginResponse.user[0].userId,
                    documentTypeAnswersObject: JSON.stringify(formValues),
                  };
                  api
                    .post(`answer/save`, {
                      body: {
                        ...answerObject,
                        tasks: tasks,
                      },
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then((res) => {
                      setMsgDialog(true);
                      setToastMessage(res.message);
                      setToastSeverity("success");
                      SetToastopen(true);
                      setTimeout(() => {
                        onCloseMainDialog && onCloseMainDialog();
                      }, 1200);

                      history.push("/user-applications");

                      if (res.status) {
                        files.map(async (file) => {
                          const reader = new FileReader();

                          reader.onloadend = async function () {
                            const response = await api.post(`upload/file`, {
                              body: {
                                ...file,
                                fileData: reader.result,
                              },
                            });
                            // if (response.success) {
                            //   console.log("file uploaded");
                            //   showFormDoc();
                            // }
                          };
                          reader.readAsDataURL(file.fileData);
                        });
                      }
                    });
                }
                // "values" here is an object representing the form values
              }}
            >
              {({
                errors,
                submitCount,
                isSubmitting,
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <Box
                  padding={0}
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "90%",
                  }}
                >
                  <Form
                    style={{
                      height: "58vh",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      paddingRight: "10PX",
                    }}
                  >
                    {formDetails.map((group, index) => {
                      const isMultiColumn = group.form.length > 5;
                      const splitIndex = Math.ceil(group.form.length / 2);
                      const formColumn1 = isMultiColumn
                        ? group.form.slice(0, splitIndex)
                        : group.form;
                      const formColumn2 = group.form.slice(splitIndex);
                      return (
                        <Box
                          key={index}
                          marginTop={"10px"}
                          borderBottom={1}
                          borderColor="grey.300"
                        >
                          {group.repeated && index == 0 && (
                            <>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  endIcon={<i className="bi bi-plus"></i>}
                                  onClick={() => handleAddNewRow(step, index)}
                                  style={{
                                    marginRight: "10px",
                                  }} // adjust as needed
                                >
                                  Add New Row
                                </Button>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                paddingLeft={"16px"}
                              >
                                {group.groupName}
                              </Typography>
                            </>
                          )}

                          <Grid container spacing={2} style={{ marginLeft: 0 }}>
                            <Grid item xs={isMultiColumn ? 5.5 : 12}>
                              {formColumn1.map((item: any, i: any) =>
                                renderFormFields(
                                  item,
                                  i,
                                  handleChange,
                                  values,
                                  errors,
                                  getSelections,
                                  setFieldValue,
                                  documentTagType
                                )
                              )}
                            </Grid>
                            {isMultiColumn && (
                              <>
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  variant="middle"
                                  style={{
                                    paddingLeft: 6,
                                    paddingRight: 3,
                                    color: "red",
                                  }}
                                />
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {formColumn2.map((item: any, i: any) =>
                                    renderFormFields(
                                      item,
                                      i,
                                      handleChange,
                                      values,
                                      errors,
                                      getSelections,
                                      setFieldValue,
                                      documentTagType
                                    )
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      );
                    })}
                  </Form>

                  <Grid
                    right={3}
                    sx={{
                      position: "absolute",
                      top: "88%",
                      width: "100%",
                      alignContent: "center",
                      backgroundColor: "#FFF",
                      padding: 1,
                    }}
                  >
                    {sections > 1 && (
                      <Box>
                        <Stepper
                          activeStep={step}
                          alternativeLabel
                          sx={{
                            backgroundColor: "#FFF",
                            width: "100%",
                            "& .MuiStepIcon-root": {
                              color: Themecolors.Button_bg2,
                              "&.Mui-active": {
                                color: Themecolors.button_bg1,
                              },
                              "&.Mui-completed": {
                                color: Themecolors.button_bg1,
                              },
                              "&.Mui-error": {
                                color: "#FF3D57",
                              },
                            },
                            "& .MuiStepLabel-root": {
                              "&.Mui-error": {
                                color: "#FF3D57",
                              },
                            },
                          }}
                        >
                          {Array.from({ length: sections }, (_, i) => (
                            <Step
                              key={i}
                              onClick={() => {
                                setStep(i);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <StepLabel
                                error={stepHasErrors(errors, i, formData)}
                              >{`Step  ${i + 1}`}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>{" "}
                      </Box>
                    )}
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
      <>
        {msgDialog && (
          <Toast
            open={toastopen}
            onClose={() => SetToastopen(false)}
            message={toastMessage}
            severity={toastSeverity}
          />
        )}
        {showErrorToast && formHasErrors && (
          <Toast
            open={showErrorToast}
            severity={"error"}
            onClose={() => setShowErrorToast(false)}
            message={"Some fields are required"}
          />
        )}
      </>
    </Box>
  );
};

export default DocumentForm;
export const globalPath = "http://192.168.1.14/";
export function base64ToBlob(base64: any, type = "") {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type });
  return blob;
}
