import React from "react";
import TextField from "@mui/material/TextField";
import { FieldProps } from "formik";
import { Themecolors } from "./Colors";

type NumericInputProps = FieldProps & {
  label: string;
};

const NumericInput: React.FC<NumericInputProps> = ({
  field,
  form: { touched, errors },
  label,
}) => (
  <TextField
    {...field}
    label={label}
    variant="filled"
    type="number"
    error={touched[field.name] && Boolean(errors[field.name])}
    fullWidth
    InputLabelProps={{
      sx: {
        color: Themecolors.InputText_Color1,
        "&.Mui-focused": {
          color: Themecolors.InputText_Color1,
        },
      },
    }}
    sx={{
      "& .MuiFilledInput-root.Mui-focused:after": {
        borderBottom: `2px solid ${Themecolors.Inpute_Border2}`,
      },
    }}
    InputProps={{
      inputProps: { min: 0 }, // You can set minimum and maximum values if needed
      sx: {
        color: Themecolors.InputText_Color1,
        "& .MuiInputBase-input": {
          color: Themecolors.InputText_Color1,
        },
      },
    }}
  />
);

export default NumericInput;
