import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";

function StickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");

      if (window.scrollY > 160) {
        stickyMenu.classList.add("sticky");
      } else {
        stickyMenu.classList.remove("sticky");
      }
    });
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu" style={{ zIndex: 999 }}>
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/GradWalkLogo.png"
                    }
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Study In<i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={
                            process.env.PUBLIC_URL + "/study-in-united-states"
                          }
                        >
                          United States
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-canada"}
                        >
                          Canada
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-uk"}
                        >
                          United Kingdom
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-ireland"}
                        >
                          Ireland
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-australia"}
                        >
                          Australia
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-switzerland"}
                        >
                          Switzerland
                        </Link>
                      </li>{" "}
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-uae"}
                        >
                          United Arab Emirates
                        </Link>
                      </li>{" "}
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/study-in-singapore"}
                        >
                          Singapore
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Services <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <div
                        className="box-content"
                        style={{
                          background:
                            "linear-gradient(90deg, rgb(255 172 65) 0%,rgb(255 228 126) 100%)",
                          padding: "10px",
                          color: "white",
                        }}
                      >
                        <span>
                          <i
                            className="fas fa-graduation-cap"
                            style={{ marginRight: "5px" }}
                          ></i>
                          <strong>For Students</strong>
                        </span>
                      </div>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Councelling"}
                        >
                          Councelling
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Preparation"}
                        >
                          Test Preparation
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Selection"}
                        >
                          Course, Country & Unversity Selection
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/application"}
                        >
                          Applications & Admissions
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Scholarship"}
                        >
                          Scholarships
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Internship"}
                        >
                          Internship
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/education-Loan"}
                        >
                          Education Loan
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Visa-Process"}
                        >
                          Visa Processing
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Alied-Services"}
                        >
                          Allied Services
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/courses"}
                    >
                      Courses
                    </Link>

                    {/* <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-grid"}
                        >
                          Engineering
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-list"}
                        >
                          Sciences
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          Arts
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          Business
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          Law
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          Medicine
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          English for Academic Studies
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="nav-item ">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/instructors"}
                    >
                      Instructors
                    </Link>
                  </li> */}
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/events"}
                    >
                      Upcoming Events
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/blog"}
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Company <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/about"}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/News-Press"}
                        >
                          News & Press
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/Career"}
                        >
                          Careers (We are Hiring)
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="apply-btn">
                  <Link to={process.env.PUBLIC_URL + "/registration"}>
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
