import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import CoursePrice from "./CoursePrice";
import PopularCourse from "./PopularCourse";
import CourseTag from "./CourseTag";
import CourseCategory from "./CourseCategory";
import CourseSearch from "./CourseSearch";

interface CourseSidebarProps {
  onSearchChange?: (query: string) => void;
  onPriceChange?: (selectedPriceRanges: string[]) => void;
  courses: any[];
  onConcentrationChange?: (concentration: string | null) => void;
  onChangeSelectCategory?: (selectedCategories: string[]) => void;
  counts: {
    all: number;
    belowTenThousand: number;
    betweenTenAndTwentyThousand: number;
    aboveTwentyThousand: number;
  };
}

class CourseSidebar extends Component<CourseSidebarProps> {
  handleSearchChange = (query: string) => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(query);
    }
  };

  handlePriceChange = (selectedPriceRanges: string[]) => {
    if (this.props.onPriceChange) {
      this.props.onPriceChange(selectedPriceRanges);
    }
  };

  handleConcentrationChange = (concentration: string | null) => {
    if (this.props.onConcentrationChange) {
      this.props.onConcentrationChange(concentration);
    }
  };

  handleCourseCategory = (selectedCategories: string[]) => {
    if (this.props.onChangeSelectCategory) {
      this.props.onChangeSelectCategory(selectedCategories);
    }
    // console.log("Selected categories:", selectedCategories);
  };
  count = () => {};
  render() {
    const { counts } = this.props;
    return (
      <div className="course-sidebar">
        <Row>
          {/* <Col md="12">
            <CourseSearch onSearchChange={this.handleSearchChange} />
          </Col> */}
          <Col md="12">
            <CourseCategory
              courses={this.props.courses}
              onChangeSelectCategory={this.handleCourseCategory}
            />
          </Col>
          <Col md="12">
            <CoursePrice
              OnChangePrice={this.handlePriceChange}
              counts={counts}
            />
          </Col>
          <Col md="12">
            <PopularCourse courses={this.props.courses} />
          </Col>
          <Col md="12">
            <CourseTag
              courses={this.props.courses}
              onConcentrationChange={this.handleConcentrationChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CourseSidebar;
